<template>
    <div class="main-div">
      <div class="container">
        <div class="columns">
          <div class="column is-one-third-desktop is-offset-one-third-desktop">
            <div class="is-flex is-flex-direction-column is-align-items-center">
              <div>
                <img src="https://i.imgur.com/uA3fRLZ.png" class="image" alt="logo">
              </div>
              <div v-if="processing && !hasError" class="is-flex is-flex-direction-column is-justify-content-center">
                <div>{{ $t('VetData.WaitForAuthentication') }}</div>
                <progress class="progress is-primary"></progress>
              </div>
              <div v-if="hasError" class="is-flex is-flex-direction-column is-align-content-center">
                <div class="mt-5 has-text-centered">
                  <span class="fa fa-times has-text-danger fa-6x"></span>
                </div>
                <p class="is-size-5 has-text-centered">
                  {{ $t('VetData.CalendarAssociationProblem') }} 
                </p>
                <p class="has-text-grey has-text-centered" v-html="replaceSpecialCharacters($t('Customer.ContactSupport'))">
                </p>
                <p class="control button_container">
                  <a @click="goToVetDataMeth($t)" class="button is-light">
                    {{ $t('General.OK') }}
                  </a>
                </p>
              </div>
              <div v-if="!processing && !hasError" class="is-flex is-flex-direction-column is-align-content-center">
                <div class="mt-5">
                  <span class="fa fa-check has-text-success fa-6x"></span>
                </div>
                <div class="mt-5 has-text-centered">
                  <p class="is-size-3">
                    {{ $t('General.ThankYou') }}
                  </p>
                  <p class="is-size-5">
                    {{ $t('VetData.CalendarAssociationConfirmed') }}
                  </p>
                  <p class="control button_container">
                    <a @click="goToVetDataMeth($t)" class="button is-light">
                      {{ $t('General.OK') }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Notyf } from "notyf";
  import { changeLocale } from "@/main"; // Import the changeLocale function

  const notyf = new Notyf()

  export default {
    name: 'AuthCallback',
    data() {
      return {
        processing: true,
        hasError: false,
        vet_id: 0,
        lang: '',
        access: ''
      }
    },
    created() {
      this.processCallback();
    },
    methods: {
      
      replaceSpecialCharacters(text)
      {
        console.log('Original text:', text);
        // Regular expression to replace '%at%' with '@'
        const replacedText = text.replace(/%at%/g, '@');
        console.log('Replaced text:', replacedText);
        return replacedText;
      },
      async processCallback() {
        console.log('Processing callback');
        const urlParams = new URLSearchParams(window.location.search);
        console.log(urlParams);
        const token = urlParams.get('token');
        console.log(token);
        const state = urlParams.get('state');
        console.log(state);
      
        // Manually parse the state parameter
        const stateParams = {};
        state.split(' ').forEach(param => {
            const [key, value] = param.split(':');
            stateParams[key] = value;
        });
        console.log(stateParams);
        this.vet_id = stateParams['vet_id'];
        console.log(this.vet_id);
        this.lang = stateParams['lang'];
        console.log(this.lang);
        try
        {
          await changeLocale(this.lang);
        }
        catch (error)
        {
          console.error('Failed to change language:', error);
        }
        this.access = stateParams['access'];
        console.log(this.access);
      
        if (token) {
          // Dispatch the Vuex action to update the vet record
          try {
            await this.$store.dispatch('updateVetToken', {
              vet_id: this.vet_id,
              account_token: token
            });
            this.processing = false;
            this.hasError = false;
          } catch (error) {
            this.processing = false;
            this.hasError = true;
            console.error('Failed to update vet token:', error);
          }  
        } else {
          this.processing = false;
          this.hasError = true;
          console.error('Authentication failed');
        }
      },
      goToVetDataMeth($t) {
        const lang = this.lang;
        const access = this.access;
        this.$router.push({ 
              name: 'VetData', 
              query: { lang, access } 
            });
      }
    }, 
  }
  </script>