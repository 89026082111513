<template>
  <div class="main-div">
    <div class="container">
      <div class="columns">
        <div class="column is-one-third-desktop is-offset-one-third-desktop">

          <!-- USER EMAIL -->
          <div v-if="!isEmailFilled">
            <div class="is-flex is-flex-direction-column is-align-items-center">
              <div>
                <img src="https://i.imgur.com/uA3fRLZ.png" class="image" alt="logo">
              </div>
              <div class="mt-4">
                <form @submit.prevent="confirmEmail">

                  <!-- DATE TIME FIELD -->
                  <div class="field">
                    <label class="label">Schedule appointment</label>
                    <div class="control">
                      <input v-model.trim="datetime" class="input" id="schedule" required>
                    </div>
                    <p class="help">Insert the appointment date and time</p>
                  </div>

                  <!-- USER EMAIL -->
                  <div class="field">
                    <label class="label">Your email</label>
                    <div class="control">
                      <input v-model.trim.lazy="email" class="input" type="email" placeholder="vet@doctor.com" required>
                    </div>
                    <p class="help">Insert your registered email</p>
                  </div>
                  <button type="submit" class="mt-5 button is-primary is-fullwidth">Confirm appointment</button>
                </form>
              </div>
            </div>
          </div>

          <div v-else>
            <!-- CHECKING MEETING -->
            <div class="is-flex is-flex-direction-column is-align-items-center" v-if="isConfirming">
              <div>
                <img src="https://i.imgur.com/uA3fRLZ.png" class="image" alt="logo">
              </div>
              <div class="mt-5">
                <span class="fa fa-spinner fa-spin has-text-grey-light fa-6x"></span>
              </div>
              <div class="mt-4 has-text-centered">
                <p class="is-size-5">
                  Confirming appointment...
                </p>
              </div>
            </div>

            <!-- CHECKING MEETING -->
            <div class="is-flex is-flex-direction-column is-align-items-center" v-if="!isConfirming && !isConfirmed">
              <div>
                <img src="https://i.imgur.com/uA3fRLZ.png" class="image" alt="logo">
              </div>
              <div class="mt-5">
                <span class="fa fa-times-circle has-text-danger fa-6x"></span>
              </div>
              <div class="mt-4 has-text-centered">
                <p class="is-size-5">
                  Cant activate this appointment!
                </p>
                <button class="button is-warning mt-5" @click="reset">Check inserted email</button>
              </div>
            </div>

            <!-- CONFIRMED -->
            <div class="is-flex is-flex-direction-column is-align-items-center" v-if="isConfirmed">
              <div>
                <img src="https://i.imgur.com/uA3fRLZ.png" class="image" alt="logo">
              </div>
              <div class="mt-5">
                <span class="fa fa-check has-text-success fa-6x"></span>
              </div>
              <div class="mt- has-text-centered">
                <p class="is-size-5">
                  Appointment confirmed
                </p>
                <p class="has-text-grey">
                  An email has been sent to you and the user with access details.
                </p>
              </div>
              <div class="mt-4">
                <p>
                  <router-link :to="appointmentURL" class="button is-primary">Access appointment</router-link>
                </p>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';
import flatpickr from 'flatpickr'

export default defineComponent({
  name: "ConfirmAppointment",
  data() {
    return {
      pin: '',
      email: '',
      datetime: '',
      isEmailFilled: false,
      isConfirming: true,
      isConfirmed: false,
    }
  },
  methods: {
    reset() {
      this.isEmailFilled = false
      this.isConfirming = true
    },
    confirmEmail() {
      if (this.email.length >= 6) {
        this.isEmailFilled = true
        this.confirmAppointment()
      }
    },
    confirmAppointment() {
      // get appointment activation hash from url
      const appointmentConfirmationHash = this.$route.params['hash']
      let date = new Date()

      // request API appointment confirmation
      this.$store.dispatch('confirmAppointment', {
        hash: appointmentConfirmationHash,
        email: this.email,
        schedule: this.datetime,
        timezone_diff: date.getTimezoneOffset() * 60
      }).then((data: any) => {
        this.pin = data['appointment_pin']
        this.isConfirming = false
        this.isConfirmed = true
      }).catch(() => {
        this.isConfirming = false
        this.isConfirmed = false;
      })
    }
  },
  mounted() {
    flatpickr(`#schedule`, {
      enableTime: true,
      time_24hr: true,
      dateFormat: "Y-m-d H:i",
      minDate: 'today'
    })
  },
  computed: {
    appointmentURL(): string {
      return `/appointment/${this.pin}`
    }
  }
});
</script>

<style scoped>
.main-div {
  height: 100vh;
  display: flex;
  align-items: center;
}
</style>
