<template>
  <div class="main-div">
    <div class="container">
      <div class="columns">
        <div class="column is-one-third-desktop is-offset-one-third-desktop">
          <div class="is-flex is-flex-direction-column is-align-items-center">
            <div>
              <img src="https://i.imgur.com/uA3fRLZ.png" class="image" alt="logo">
            </div>
            <div v-if="validating && !hasError" class="is-flex is-flex-direction-column is-justify-content-center">
              <div>Wait a moment please...</div>
              <progress class="progress is-primary"></progress>
            </div>
            <div v-if="hasError" class="is-flex is-flex-direction-column is-align-content-center">
              <div class="mt-5 has-text-centered">
                <span class="fa fa-times has-text-danger fa-6x"></span>
              </div>
              <p class="is-size-5 has-text-centered">
                There was a problema validating your payment.
              </p>
              <p class="has-text-grey has-text-centered">
                Contact our support at <a href="mailto:support@vetexpertise.com">support@vetexpertise.com</a>
              </p>
            </div>
            <div class="mt-5" v-if="!validating">
              <span class="fa fa-check has-text-success fa-6x"></span>
            </div>
            <div v-if="!validating" class="mt-5 has-text-centered">
              <p class="is-size-3">
                Thank you!
              </p>
              <p class="is-size-5">
                Your request has been sent to our specialist.
              </p>
              <p class="has-text-grey">
                We will confirm it as soon as possible and send you an email with instructions.
              </p>
              <router-link to="/meeting"></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

export default {
  name: "RequestSent",
}
</script>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

const route = useRoute()
const store = useStore()
const paymentHash = ref()
const validating = ref(true)
const hasError = ref(false)

onMounted(async () => {
  paymentHash.value = route.params.payment_hash

  /* validate payment */
  try {
    const validated = await store.dispatch('confirmPayment', paymentHash.value)

    /* turn off validation state */
    if (validated) {
      validating.value = false
    }/* turn off validation state */
    if (validated) {
      validating.value = false
    }
  } catch (e) {
    hasError.value = true
  }


})
</script>

<style scoped>
.main-div {
  height: 100vh;
  display: flex;
  align-items: center;
}
</style>
