<template>
  <div class="columns">
    <div class="column is-4">
      <div class="field">
        <label class="label">{{ $t('FilesData.File') }}</label>
        <div class="control">
          <input type="file" @change="handleFileToAdd">
        </div>
      </div>
    </div>
    <div class="column is-8">
      <div class="field">
        <label class="label">{{ $t('FilesData.ContentDescription') }}</label>
        <div class="control">
          <input :minlength='2' :maxlength='80' v-model="getEditedAppointmentNewFileData.file_description" class="input"
            type="text" placeholder="e.g. X-Ray" :disabled="!getEditingAppointment" required>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, ref } from "vue";
import { mapActions, mapGetters } from "vuex";
import { Notyf } from "notyf";

export default defineComponent
  (
    {
      name: "AppointmentDataFileDataForm",
      components:
        {},

      data() {
        return {
          componentName: "AppointmentDataFileDataForm",
        }
      },
      computed:
      {
        ...mapGetters(['getEditedAppointmentNewFileData', 'getEditingAppointment']),
      },
      methods:
      {
        ...mapActions(['iniEditedAppointmentNewFile', 'updateNewFileData']),

        clearFormFile() {
          this.iniEditedAppointmentNewFile()
        },
        handleFileToAdd(event: Event) {
          console.log('handleFileToAdd');
          // Type assertion to tell TypeScript that event.target is an HTMLInputElement
          const inputElement = event.target as HTMLInputElement;
          // Access the selected file from the input element
          if (inputElement.files && inputElement.files.length > 0) {
            // Access the first selected file
            const file = inputElement.files[0];
            // Store the selected file in the component's property
            // You can access file properties like file.name, file.size, etc.
            this.updateNewFileData(file)
            console.log('Selected file:', file);
          }
        }

      },
      watch: {},
      created() { },
      mounted() { }
    }
  )

</script>

<style scoped>
.content-body {
  margin-top: 10px;
}

.content-body {
  margin-top: 10px;
}
</style>