import { createStore } from 'vuex'
import { appointment } from "./modules/store.appointment";
import { users } from "./modules/store.users";
import { editedManager, manager } from "./modules/store.manager";
import { newCustomer } from "./modules/store.customer_reg";
import { editedCustomer } from "./modules/store.customer";
import { editedVet } from "./modules/store.vet";
import { editedAvailabilityConfig } from "./modules/store.vet";
import { products } from "./modules/store.products";


export default createStore({
    modules: {
        appointment,
        users,
        manager,
        newCustomer,
        editedCustomer,
        editedVet,
        editedManager,
        editedAvailabilityConfig,
        products
    }
})
