<template>
  <div>
    <Navbar></Navbar>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="content">
            <form @submit.prevent="editCustomerMeth" v-if="!getShowingAppointmentData">
              <div class="content-body">
                <form @submit.prevent="editCustomerMeth" v-if="!getShowingPetRecord">
                  <div class="header">
                    <h5 class="title is-5" v-if="getEditingCustomer">
                      {{ $t('CustomerData.PleaseModifyYourCustomerData') }}
                    </h5>
                    <h5 class="title is-5" v-if="!getEditingCustomer">
                      {{ $t('CustomerData.YourCustomerData') }}
                    </h5>
                  </div>
                  <div class="columns">
                    <div class="column is-half">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Name') }}</label>
                        <div class="control">
                          <input :minlength='2' v-model="getEditedCustomerData.name" class="input" type="text"
                          :placeholder="$t('CustomerData.NamePlaceholder')" required :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Email') }}</label>
                        <div class="control">
                          <input v-model="getEditedCustomerData.email" class="input" type="email"
                            :placeholder="replaceSpecialCharacters($t('CustomerData.EmailPlaceholder'))" required :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('UserData.PhoneNumber') }}</label>
                        <div class="control">
                          <input :minlength='6' v-model="getEditedCustomerData.phone" class="input" type="tel"
                          :placeholder="$t('UserData.PhoneNumberPlaceholder')" required :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.VATNumber') }}</label>
                        <div class="control">
                          <input v-model="getEditedCustomerData.tax_id" class="input" type="text"
                          :placeholder="$t('CustomerData.VATNumberPlaceholder')" required :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Address') }}</label>
                        <div class="control">
                          <input v-model="getEditedCustomerData.address" class="input" type="text"
                          :placeholder="$t('CustomerData.AddressPlaceholder')" required
                            :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.VetCenter') }}</label>
                        <div class="control">
                          <input v-model="getEditedCustomerData.vet_center" class="input" type="text"
                          :placeholder="$t('CustomerData.VetCenterPlaceholder')" required
                            :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="header">
                <h5 class="title is-6 mt-4" v-if="!addingPet && !getEditedCustEditingPet && !getShowingPetRecord">
                  {{ $t('CustomerData.YourPets') }}
                </h5>
              </div>
              <div class="content-body">
                <table class="table" v-if="!addingPet && !getEditedCustEditingPet && !getShowingPetRecord">
                  <thead>
                    <tr>
                      <th class="">{{ $t('PetData.PetName') }}</th>
                      <th class="">{{ $t('PetData.Species') }}</th>
                      <th class="">{{ $t('PetData.Breed') }}</th>
                      <th class="">{{ $t('PetData.Gender') }}</th>
                      <th class="">{{ $t('PetData.Birth') }}</th>
                      <th class="">{{ $t('PetData.Age') }}</th>
                      <th class="">{{ $t('PetData.FertileStatus') }}</th>
                      <th class="">{{ $t('PetData.Weight') }}</th>
                      <th>{{ $t('General.Operations') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="pet of getEditedCustomerPets" :key="pet.pet_id">
                      <CustDataPetTableRow :pet-data="pet" :language="language"></CustDataPetTableRow>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="content-body">
                <div class="header is-flex is-justify-content-space-between" v-if="!addingPet && !getEditedCustEditingPet
              && getEditingCustomer && !getShowingPetRecord">
                  <div>
                    <button class="button is-primary" @click="toggleAddingPet"><strong>{{ $t('CustomerData.NewPet') }}</strong></button>
                  </div>
                </div>
              </div>
              <div class="content-body">
                <div class="field is-grouped is-grouped-right">
                  <p class="control"
                    v-if="!addingPet && !getEditedCustEditingPet && getEditingCustomer && !getShowingPetRecord">
                    <button type="submit" class="button is-primary">{{ $t('General.Save') }}</button>
                  </p>
                  <p class="control"
                    v-if="!addingPet && !getEditedCustEditingPet && getEditingCustomer && !getShowingPetRecord">
                    <a @click="cancelEditing" class="button is-light">
                      {{ $t('General.Cancel') }}
                    </a>
                  </p>
                  <p class="control"
                    v-if="!addingPet && !getEditedCustEditingPet && !getEditingCustomer && !getShowingPetRecord && !getCustDetailing">
                    <a @click="iniCustomerEditing" class="button is-primary">
                      {{ $t('General.ModifyData') }}
                    </a>
                  </p>
                  <p class="control" v-if="getCustDetailing && !getShowingPetRecord">
                    <a @click="endCustDetailing" class="button is-primary">
                      {{ $t('General.Back') }}
                    </a>
                  </p>
                </div>
              </div>
              <div class="content-body">
                <form @submit.prevent="addPetMeth" v-if="addingPet">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('CustomerData.AddNewPet') }}
                    </h5>
                  </div>
                  <CustDataPetDataForm ref="refNewPetDataForm"></CustDataPetDataForm>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control">
                      <button type="submit" class="button is-primary">{{ $t('General.Add') }}</button>
                    </p>
                    <p class="control">
                      <a @click="toggleAddingPet" class="button is-light">
                        {{ $t('General.Cancel') }}
                      </a>
                    </p>
                  </div>
                </form>
              </div>
              <div class="content-body">
                <form @submit.prevent="editPetMeth" v-if="getEditedCustEditingPet">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('CustomerData.EditPet') }}
                    </h5>
                  </div>
                  <CustDataPetDataForm ref="refEditedPetDataForm"></CustDataPetDataForm>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control">
                      <button type="submit" class="button is-primary">{{ $t('General.Edit') }}</button>
                    </p>
                    <p class="control">
                      <a @click="cancelEditPet" class="button is-light">
                        {{ $t('General.Cancel') }}
                      </a>
                    </p>
                  </div>
                </form>
              </div>
              <div class="content-body">
                <form @submit.prevent="endShowingPetRecordMeth" v-if="getShowingPetRecord">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('PetData.PetHistoricalRecord') }}
                    </h5>
                  </div>
                  <CustDataPetDataForm ref="refPetRecordDataForm"></CustDataPetDataForm>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control">
                      <button type="submit" class="button is-primary">{{ $t('General.Back') }}</button>
                    </p>
                  </div>
                </form>
              </div>
            </form>
            <AppointmentDataForm v-if="getShowingAppointmentData"></AppointmentDataForm>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Navbar from "../components/Navbar.vue";
import { Notyf } from "notyf";
import CustDataPetTableRow from "../components/CustDataPetTableRow.vue";
import CustDataPetDataForm from "../components/CustDataPetDataForm.vue";
import AppointmentDataForm from "../components/AppointmentDataForm.vue";
import { useRoute } from 'vue-router';
import { changeLocale } from "@/main"; // Import the changeLocale function

const notyf = new Notyf()

export default defineComponent({
  name: "CustomerData",
  components: { CustDataPetTableRow, Navbar, CustDataPetDataForm, AppointmentDataForm },

  data() {
    return {
      componentName: "CustomerData",
      addingPet: false,
      otherSpecies: false,
      route: useRoute(),
      language: ''
    }
  },
  computed: {
    ...mapGetters(['getEditedCustomerData', 'getEditedCustomerPets', 'getEditedCustNewPetData', 'getEditedCustBreeds',
      'getEditedCustEditingPet', 'getEditedCustInitialPetData', 'userData', 'getEditingCustomer', 'getShowingPetRecord',
      'getEditedInitialEmail', 'getEditedCustPetsToDelete', 'getCustDetailing', 'getShowingAppointmentData', 'getCustomerHasPets']),
    validPassword(): boolean {
      return (this.getEditedCustomerData.password === this.getEditedCustomerData.confirmation && this.getEditedCustomerData.password.length >= 6)
    },
  },
  methods: {
    ...mapActions(['addPetEditedCust', 'iniEditedCustNewPet', 'checkEmailIsTaken', 'editCustomer', 'createEditedCustPet',
      'updateEditedCustPet', 'iniEditedCustomer', 'iniEditedCustomerPets', 'updateEditedCustBreeds', 'fetchPets',
      'removePetEditedCustomer', 'toggleEditedCustPetEditing', 'editPetEditedCust', 'iniCustomerEditing', 'endCustomerEditing',
      'toggleShowingPetRecord', 'deleteEditedCustPet', 'toggleShowingUserData', 'toggleCustDetailing', 'IniInitialEmail', 'logout', 'IniAccessType']),

    replaceSpecialCharacters(text: string) {
      console.log('Original text:', text);
      // Regular expression to replace '%at%' with '@'
      const replacedText = text.replace('%at%', '@');
      console.log('Replaced text:', replacedText);
      return replacedText;
    },

    async editCustomerMeth()
    {
      console.log("editCustomerMeth")
      console.log(this.getEditedInitialEmail)
      console.log(this.getEditedCustomerData.email)
      // verify if all fields are filled
      if (!this.getEditedCustomerData.name || !this.getEditedCustomerData.email || !this.getEditedCustomerData.phone ||
          !this.getEditedCustomerData.tax_id || !this.getEditedCustomerData.address || !this.getEditedCustomerData.vet_center)
      {
        notyf.error(this.$t('CustomerData.NotyfFillAllFields'))
        return;
      }

      const emailChanged = (this.getEditedInitialEmail != this.getEditedCustomerData.email)
      console.log(emailChanged)

      var checkEmailIsTaken: boolean = false;

      if (emailChanged)
      {
        checkEmailIsTaken = await this.checkEmailIsTaken(this.getEditedCustomerData.email)
        
        if (!checkEmailIsTaken)
        {
          const confirmLogout = confirm(this.$t('UserData.ConfirmEmailChange'));
          if (confirmLogout)
          {
            try
            {
              var editedCustomerId: number = await this.editCustomer(this.getEditedCustomerData)
              console.log(editedCustomerId)

              if (editedCustomerId)
              {
                //Edit Pets in the customer
                for (var editedPetData of this.getEditedCustomerPets)
                {
                  editedPetData.user_id = editedCustomerId;
                  if (editedPetData.pet_id == 0)
                  {
                    //create new added pets
                    await this.createEditedCustPet(editedPetData)
                  }
                  else
                  {
                    //update existing pets
                    await this.updateEditedCustPet(editedPetData)
                  }
                }
                //delete removed pets
                for (var petId of this.getEditedCustPetsToDelete)
                {
                  await this.deleteEditedCustPet(petId);
                }
                this.endCustomerEditing()
                notyf.success(this.$t('CustomerData.NotyfDataEdited'))
              }
            }
            catch (e)
            {
              notyf.error(this.$t('CustomerData.NotyfErrorUpdatingData'))
            }
            // Redirect the user to the login page
            this.toggleShowingUserData(true);
            this.logout()
            this.$router.push('/')
      
            return; // Stop further execution of the method
          }
          else
          {
            // If the user cancels, reset the email field to the initial value
            this.getEditedCustomerData.email = this.getEditedInitialEmail;
            return; // Stop further execution of the method
          }
        }
        else {
          notyf.error(this.$t('CustomerData.NotyfEmailInUse'))
        }
      }
      else
      {
        try
        {
          var editedCustomerId: number = await this.editCustomer(this.getEditedCustomerData)
          console.log(editedCustomerId)

          if (editedCustomerId) {
            //Edit Pets in the customer
            for (var editedPetData of this.getEditedCustomerPets)
            {
              editedPetData.user_id = editedCustomerId;
              if (editedPetData.pet_id == 0)
              {
                //create new added pets
                await this.createEditedCustPet(editedPetData)
              }
              else
              {
                //update existing pets
                await this.updateEditedCustPet(editedPetData)
              }
            }
            //delete removed pets
            for (var petId of this.getEditedCustPetsToDelete)
            {
              await this.deleteEditedCustPet(petId);
            }
            this.endCustomerEditing()
            notyf.success(this.$t('CustomerData.NotyfDataEdited'))
          }
        }
        catch (e)
        {
          notyf.error(this.$t('CustomerData.NotyfErrorUpdatingData'))
        }
      }
    },

    cancelEditing() {
      this.endCustomerEditing()
      this.clearFormCustomer()
      this.initializeEditedCustomer()
    },

    async addPetMeth() {
      this.toggleAddingPet();
      console.log(this.getEditedCustomerPets)
      this.getEditedCustNewPetData.pet_id = 0
      this.addPetEditedCust(this.getEditedCustNewPetData)
      console.log(this.getEditedCustomerPets);
      // Cleans the form with pet data using method in child component
      // this.clearFormPet()
      (this.$refs.refNewPetDataForm as any).clearFormPet();
      notyf.success(this.$t('CustomerData.NotyfNewPetAdded'))
    },


    async editPetMeth() {
      console.log(this.getEditedCustomerPets)
      console.log(this.getEditedCustNewPetData)
      console.log('editPetMeth')
      this.editPetEditedCust(this.getEditedCustNewPetData)
      console.log(this.getEditedCustomerPets)
      this.toggleEditedCustPetEditing(true);
      // Cleans the form with pet data using method in child component
      // this.clearFormPet()
      (this.$refs.refEditedPetDataForm as any).clearFormPet();
      notyf.success(this.$t('CustomerData.NotyfPetModified'))
    },

    async endShowingPetRecordMeth() {
      console.log('endShowingPetRecordMeth')
      this.toggleShowingPetRecord(true);
      // Cleans the form with pet history data using method in child component
      // this.clearFormPet()
      (this.$refs.refPetRecordDataForm as any).clearFormPet();
    },

    cancelEditPet() {
      console.log('cancelPetMeth');
      (this.$refs.refEditedPetDataForm as any).clearFormPet();
      this.toggleEditedCustPetEditing(true)
    },


    async clearFormCustomer() {
      // This function seems not to be doing anything from where it is called
      // redundant ?
      const resultIniCust = await this.iniEditedCustomer(this.userData.email);
      this.IniInitialEmail(resultIniCust.email)
        
    },


    toggleAddingPet() {
      this.addingPet = !this.addingPet
    },


    async initializeEditedCustomer() {

      console.log("initializeEditedCustomer")
      console.log(this.getCustDetailing);
      if (!this.getCustDetailing) {
        console.log(this.userData.email);
        const resultIniCust = await this.iniEditedCustomer(this.userData.email);
        this.IniInitialEmail(resultIniCust.email)
        console.log(resultIniCust);
      }
      console.log(this.getEditedCustomerData);
      console.log(this.getEditedCustomerData.user_id);
      const resultIniPets = await this.iniEditedCustomerPets(this.getEditedCustomerData.user_id);
      console.log(resultIniPets);
      console.log(this.getEditedCustomerPets);

    },

    endCustDetailing() {
      this.toggleShowingUserData(true);
      this.toggleCustDetailing(true);
      this.clearFormCustomer();
      this.$router.go(-1);
    }
  },
  watch: {},
  beforeMount() {
    this.language = this.route.query.lang as string;
    const accessType = this.route.query.access as string;

    if (this.language)
    {
      try
      {
        changeLocale(this.language);
      }
      catch (error)
      {
        console.error('Failed to change language:', error);
      }
    }
    else
    {
      console.log("lang does not exist");
    }
    if (accessType)
    {
      try
      {
        this.IniAccessType(accessType);
      }
      catch (error)
      {
        console.error('Failed to set access type:', error);
      }
    }
    else
    {
      console.log("access type does not exist");
    }
    this.initializeEditedCustomer();
  },
  mounted() {
    if(!this.getCustomerHasPets)
    {
      this.iniCustomerEditing();
      this.toggleAddingPet();
    }
  }

})
</script>

<style scoped>
.content-body {
  margin-top: 10px;
}

.content-body {
  margin-top: 10px;
}
</style>