<template>
    <section class="hero is-fullheight p-6">
      <div class="container has-background-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
        <div>
          <img src="https://i.imgur.com/uA3fRLZ.png" class="image logo-image" alt="logo">
        </div>
        <form @submit.prevent="createNewPassword" class="p-3">
          <div class="field">
            <p class="control has-icons-left">
              <input class="input" type="password" :placeholder="$t('UserData.PasswordPlaceholder')" v-model="password" required>
            </p>
          </div>
          <div class="field">
            <p class="control has-icons-left">
              <input class="input" type="password" :placeholder="$t('UserData.PassConfirmationPlaceholder')" v-model="confirmPassword" required>
            </p>
          </div>
          <div class="field">
            <p class="control has-text-centered">
              <button class="button is-success is-fullwidth" :class="{ 'is-loading': isProcessing }">
                {{ $t('UserData.CreateNewPassword') }}
              </button>
            </p>
          </div>
        </form>
      </div>
    </section>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import { mapActions } from "vuex";
  import { Notyf } from "notyf";
  import { changeLocale } from "@/main"; // Import the changeLocale function

  
  const notyf = new Notyf();
  
  export default defineComponent({
    name: "NewPassword",
    data() {
      return {
        password: '',
        confirmPassword: '',
        isProcessing: false,
      };
    },
    methods: {
      ...mapActions(['updatePassword']),
      async createNewPassword() {
        if (this.password !== this.confirmPassword) {
          notyf.error(this.$t('UserData.NotyfErrorPasswordsMismatch'));
          return;
        }
  
        this.isProcessing = true;
        try
        {
          const token = this.$route.query.token; // Get the token from the URL query parameters
          const result = await this.updatePassword({ password: this.password, token });
          if (result)
          {
            notyf.success(this.$t('UserData.PasswordResetSuccess'));
            this.$router.push('/');
          }
          else
          {
            notyf.error(this.$t('UserData.ErrorResettingPassword'));
          }
        }
        catch (error)
        {
          notyf.error(this.$t('UserData.ErrorResettingPassword'));
        }
        finally
        {
          this.isProcessing = false;
        }
      }
    },

    beforeMount()
    {
      const lang = this.$route.query.lang as string;

      if (lang)
      {
        try
        {
          changeLocale(lang);
        }
        catch (error)
        {
          console.error('Failed to change language:', error);
        }
      }
      else
      {
        console.log("lang does not exist");
      }
    },
  });
  </script>
  
  <style scoped>
  .logo-image {
    height: 8em;
  }
  </style>