<template>
  <th>
    {{ productData.product_id }}
  </th>
  <td>
    <div>{{ productData.designation }}</div>
  </td>
  <td>
    <div v-if="!editing">{{ productData.price }}</div>
    <input v-else type="number" v-model="editedPrice" class="input" step="0.01">
  </td>
  <td>
    <div class="is-flex is-justify-content-start" style="gap: 1em;">
      <button v-if="!editing" class="button is-warning is-small" @click="toggleEditing">{{ $t('General.Edit') }}</button>
      <button v-else class="button is-success is-small" @click="save($t)">{{ $t('General.Save') }}</button>
    </div>
  </td>
</template>

<script lang="ts">
import { defineComponent, defineProps } from 'vue'
import { Notyf } from "notyf";
import { useI18n } from "vue-i18n";


const notyf = new Notyf()

export default defineComponent({
  name: "ProductTableRow"
})
</script>
<script lang="ts" setup>
import { useStore } from "vuex";
import { computed, onMounted, ref, Ref } from "vue";

const props = defineProps(['productData'])
const store = useStore()
const { locale } = useI18n();
const editing = ref(false)
const editedPrice = ref('')

const toggleEditing = () => {
  editedPrice.value = props.productData.price
  editing.value = !editing.value
}

const save = async ($t: any) => {


  try {
    await store.dispatch('changePrice', {
      price: editedPrice.value,
      product_id: props.productData.product_id,
    })
    const language = locale.value;
    await store.dispatch('fetchProducts', language)

    toggleEditing()
    notyf.success($t('ProductData.NotyfDataEdited'))
  } catch (e) {
    notyf.error($t('ProductData.NotyfErrorUpdatingData'))
  }

}


onMounted(() => {

})
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
