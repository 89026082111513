<template>
  <td>
    <!-- Assuming fileUrl is the variable holding the URL -->
    <a :href="fileData.file_url" :download="fileData.file_name">{{ fileData.file_name }}</a>
  </td>
  <td>
    <div>{{ fileData.file_description }}</div>
  </td>
  <td>
    <div>{{ fileData.file_type }}</div>
  </td>
  <td>
    <div>{{ fileData.file_size }}</div>
  </td>
  <td>
    <div>{{ fileData.upload_time }}</div>
  </td>
  <td>
    <div class="is-flex is-justify-content-start" style="gap: 1em;">
      <div v-if="editingAppointment()">
        <button type="button" class="button is-danger is-small" @click="remove(fileData, $t)">Delete</button>
      </div>
    </div>
  </td>
</template>


<script lang="ts">
import { defineComponent, defineProps, onMounted } from 'vue'
import { useStore } from "vuex";
import { Notyf } from "notyf";

const props = defineProps(['fileData'])
const store = useStore()

const notyf = new Notyf()

export default defineComponent({
  name: "AppointmentDataFileTableRow",
})

</script>
<script lang="ts" setup>

const props = defineProps(['fileData'])
const store = useStore()

function editingAppointment(): boolean {
  console.log(store.getters.getEditingAppointment)
  return (store.getters.getEditingAppointment)
}

const remove = async (fileData: any, $t: any) => {
  if (confirm('Do you want to remove this file?')) {
    //TODO:Create notification to ask if it is really to delete
    console.log("remove")
    console.log(store.getters.getEditedAppointmentFiles)
    if (fileData.file_id != 0) {
      // Store appointment to be deleted in the DB when saving data.
      // Only of it hasn't been added in this interaction
      await store.dispatch('saveFileToBeDeleted', fileData.file_id)
    }
    try {
      // Remove file from the list front end
      await store.dispatch('removeFileEditedAppointment', fileData.file_pos)
      console.log(store.getters.getEditedAppointmentFiles)
      notyf.success($t('FilesData.NotyfFileRemoved'))
    } catch (e) {
      notyf.error($t('FilesData.NotyfErrorRemoveFile'))
    }
  }
}

onMounted(() => {
  console.log("Mounted AppointmentDataFileTableRow")

})

</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
