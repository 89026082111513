import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-077e8ab6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "columns" }
const _hoisted_2 = { class: "column is-4" }
const _hoisted_3 = { class: "field" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "control" }
const _hoisted_6 = { class: "column is-8" }
const _hoisted_7 = { class: "field" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "control" }
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('FilesData.File')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("input", {
            type: "file",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileToAdd && _ctx.handleFileToAdd(...args)))
          }, null, 32)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('FilesData.ContentDescription')), 1),
        _createElementVNode("div", _hoisted_9, [
          _withDirectives(_createElementVNode("input", {
            minlength: 2,
            maxlength: 80,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getEditedAppointmentNewFileData.file_description) = $event)),
            class: "input",
            type: "text",
            placeholder: "e.g. X-Ray",
            disabled: !_ctx.getEditingAppointment,
            required: ""
          }, null, 8, _hoisted_10), [
            [_vModelText, _ctx.getEditedAppointmentNewFileData.file_description]
          ])
        ])
      ])
    ])
  ]))
}