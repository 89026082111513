<template>
  <div>
    <Navbar></Navbar>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="content">
            <div class="header is-flex is-justify-content-space-between">
              <div>
                <h4 class="title is-4">
                  {{ $t('Vet.Mode') }}
                </h4>
                <h6 class="subtitle is-6 has-text-grey"
                  v-if="getVetMode == 'availabilityconfig' && !editingAvailabilityConfig">
                  {{ $t('Vet.AvailabilityCalendar') }}
                </h6>
                <h6 class="subtitle is-6 has-text-grey"
                  v-if="getVetMode == 'availabilityslots'">
                  {{ $t('Vet.VisualizeSlots') }}
                </h6>
                <h6 class="subtitle is-6 has-text-grey" v-if="getVetMode == 'customers'">
                  {{ $t('Vet.VisualizeCustomers') }}
                </h6>
                <h6 class="subtitle is-6 has-text-grey" v-if="getVetMode == 'appointments' && !getShowingAppointmentData">
                  {{ $t('Vet.VisualizeMyAppointments') }}
                </h6>
              </div>
            </div>
            <div class="content-body">
              <!-- Customers list  -->
              <table class="table" v-if="getVetMode == 'customers'">
                <thead>
                  <tr>
                    <th>{{ $t('UserData.Id') }}</th>
                    <th class="">{{ $t('UserData.Name') }}</th>
                    <th class="">{{ $t('UserData.Email') }}</th>
                    <th class="">{{ $t('UserData.Phone') }}</th>
                    <th class="">{{ $t('CustomerData.Address') }}</th>
                    <th class="">{{ $t('CustomerData.VATNumber') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user of getUsers" :key="user.user_id">
                    <UserTableRow :user-data="user" :parentShowCustomerData="showCustomerData"></UserTableRow>
                  </tr>
                </tbody>
              </table>
              
              <!-- Appointments list  -->
              <!-- Date Range Filter -->
              <div v-if="getVetMode == 'appointments' && !getShowingAppointmentData" class="field is-grouped mb-4">
                <label class="label mr-2">{{ $t('General.From') }}</label>
                <div class="control">
                  <VueDatePicker id="filter_start_date" v-model="filterStartDate" :placeholder="$t('General.PleaseSelectDate')"
                  model-type="yyyy-MM-dd" auto-apply :min-date="new Date(1950, 0, 1)" :max-date="maxDate"
                  :enable-time-picker="false" prevent-min-max-navigation format="yyyy-MM-dd"/> 
                </div>
                <label class="label mr-2">{{ $t('General.To') }}</label>
                <div class="control">
                  <VueDatePicker id="filter_end_date" v-model="filterEndDate" :placeholder="$t('General.PleaseSelectDate')"
                  model-type="yyyy-MM-dd" auto-apply :min-date="new Date(1950, 0, 1)" :max-date="maxDate"
                  :enable-time-picker="false" prevent-min-max-navigation format="yyyy-MM-dd"/>
                </div>
                <div class="control">
                  <button @click="resetDateRange" class="button is-light">{{ $t('General.ResetFilter') }}</button>
                </div>
              </div>
              
              <div class="table-container mb-2 " v-if="getVetMode == 'appointments' && !getShowingAppointmentData" style="max-height: 500px; overflow-y: auto;">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="">{{ $t('AppointmentData.Appointment') }}</th>
                      <th class="">{{ $t('AppointmentData.Customer') }}</th>
                      <th class="">{{ $t('AppointmentData.Pet') }}</th>
                      <th class="">{{ $t('AppointmentData.Status') }}</th>
                      <th class="">{{ $t('AppointmentData.StatusTime') }}</th>
                      <th class="">{{ $t('AppointmentData.Link') }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="appointment of filteredAppointments" :key="appointment.appointment_id">
                      <VetRecordTableRow :appointment-data="appointment"></VetRecordTableRow>
                    </tr>
                  </tbody>
                </table>
              </div>
              <AppointmentDataForm v-if="getVetMode == 'appointments' && getShowingAppointmentData"></AppointmentDataForm>
              <form @submit.prevent="editAvailabilityConfigMeth" v-if="getVetMode == 'availabilityconfig'">
                <!-- Month selection-->
                <div class="columns is-vcentered">
                  <div class="column is-4 ">
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label">{{ $t('Vet.AvailabilityMonth') }}</label>
                      </div>
                      <div class="field-body">
                        <div class="field">
                          <div class="control">
                            <div class="select is-fullwidth">
                              <select v-model="aurinko_profile_id" required class="px-6" @change="confirmMonthlyProfileChange(aurinko_profile_id, $t)">
                                <option v-for="profile of getAurinkoProfiles" :key="profile.month_nr" :value="profile.aurinko_profile_id">{{ $t('General.' + profile.month) }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--
                  <div class="column is-1 ">
                    <div class="field is-horizontal">
                      <div class="field-label is-normal">
                        <label class="label">Profile: </label>
                      </div>
                      <div class="field-body">
                        <div class="field mt-2">
                          <div class="control">
                            <p>{{ aurinko_profile_id }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  -->
                  <div class="column is-8 ">
                    <div class="notification is-light is-small" v-html="$t('Vet.ImpactNote')">
                    </div>
                  </div>
                </div>
                <div class="box availability-config-box">
                  <div class="columns is-1">
                    <div class="column">
                      <table class="table is-narrow is-fullwidth">
                        <thead>
                          <tr>
                            <th class="has-text-centered mx-0 px-0" colspan="3">{{ $t('General.Monday') }}</th>
                            <th class="mx-0 px-0">
                              <div class="is-flex is-justify-content-start mx-0 px-0">
                                <button type="button" class="button is-primary is-light is-small is-rounded "
                                  @click="addWorkingIntervalMeth('monday')">
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="obtainAvailabilityConfigWorkingIntervals('monday').length == 0">
                            <td class="has-text-centered mx-0 px-0" colspan="4">{{ $t('General.Unavailable') }}</td>
                          </tr>
                          <tr class="mx-0" v-for="workingInterval of obtainAvailabilityConfigWorkingIntervals('monday')"
                            :key="workingInterval.pos">
                            <WorkingIntervalTableRow :interval-data="workingInterval" @changes-made="flagChangesMade(true)">
                            </WorkingIntervalTableRow>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="column">
                      <table class="table is-narrow is-fullwidth">
                        <thead>
                          <tr>
                            <th class="has-text-centered mx-0 px-0" colspan="3">{{ $t('General.Tuesday') }}</th>
                            <th class="mx-0 px-0">
                              <div class="is-flex is-justify-content-start mx-0 px-0">
                                <div>
                                  <button type="button" class="button is-primary is-light is-small is-rounded "
                                    @click="addWorkingIntervalMeth('tuesday')">
                                    <i class="fa-solid fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="obtainAvailabilityConfigWorkingIntervals('tuesday').length == 0">
                            <td class="has-text-centered mx-0 px-0" colspan="4">{{ $t('General.Unavailable') }}</td>
                          </tr>
                          <tr class="is-spaced"
                            v-for="workingInterval of obtainAvailabilityConfigWorkingIntervals('tuesday')"
                            :key="workingInterval.pos">
                            <WorkingIntervalTableRow :interval-data="workingInterval" @changes-made="flagChangesMade(true)">
                            </WorkingIntervalTableRow>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="column">
                      <table class="table is-narrow is-fullwidth">
                        <thead>
                          <tr>
                            <th class="has-text-centered mx-0 px-0" colspan="3">{{ $t('General.Wednesday') }}</th>
                            <th class="mx-0 px-0">
                              <div class="is-flex is-justify-content-start mx-0 px-0">
                                <button type="button" class="button is-primary is-light is-small is-rounded "
                                  @click="addWorkingIntervalMeth('wednesday')">
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="obtainAvailabilityConfigWorkingIntervals('wednesday').length == 0">
                            <td class="has-text-centered mx-0 px-0" colspan="4">{{ $t('General.Unavailable') }}</td>
                          </tr>
                          <tr class="is-spaced"
                            v-for="workingInterval of obtainAvailabilityConfigWorkingIntervals('wednesday')"
                            :key="workingInterval.pos">
                            <WorkingIntervalTableRow :interval-data="workingInterval" @changes-made="flagChangesMade(true)">
                            </WorkingIntervalTableRow>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="column">
                      <table class="table is-narrow is-fullwidth">
                        <thead>
                          <tr>
                            <th class="has-text-centered mx-0 px-0" colspan="3">{{ $t('General.Thursday') }}</th>
                            <th class="mx-0 px-0">
                              <div class="is-flex is-justify-content-start mx-0 px-0">
                                <button type="button" class="button is-primary is-light is-small is-rounded "
                                  @click="addWorkingIntervalMeth('thursday')">
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="obtainAvailabilityConfigWorkingIntervals('thursday').length == 0">
                            <td class="has-text-centered mx-0 px-0" colspan="4">{{ $t('General.Unavailable') }}</td>
                          </tr>
                          <tr class="is-spaced"
                            v-for="workingInterval of obtainAvailabilityConfigWorkingIntervals('thursday')"
                            :key="workingInterval.pos">
                            <WorkingIntervalTableRow :interval-data="workingInterval" @changes-made="flagChangesMade(true)">
                            </WorkingIntervalTableRow>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="column">
                      <table class="table is-narrow is-fullwidth">
                        <thead>
                          <tr>
                            <th class="has-text-centered mx-0 px-0" colspan="3">{{ $t('General.Friday') }}</th>
                            <th class="mx-0 px-0">
                              <div class="is-flex is-justify-content-start mx-0 px-0">
                                <button type="button" class="button is-primary is-light is-small is-rounded "
                                  @click="addWorkingIntervalMeth('friday')">
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="obtainAvailabilityConfigWorkingIntervals('friday').length == 0">
                            <td class="has-text-centered mx-0 px-0" colspan="4">{{ $t('General.Unavailable') }}</td>
                          </tr>
                          <tr class="is-spaced"
                            v-for="workingInterval of obtainAvailabilityConfigWorkingIntervals('friday')"
                            :key="workingInterval.pos">
                            <WorkingIntervalTableRow :interval-data="workingInterval" @changes-made="flagChangesMade(true)">
                            </WorkingIntervalTableRow>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="column">
                      <table class="table is-narrow is-fullwidth">
                        <thead>
                          <tr>
                            <th class="has-text-centered mx-0 px-0" colspan="3">{{ $t('General.Saturday') }}</th>
                            <th class="mx-0 px-0">
                              <div class="is-flex is-justify-content-start mx-0 px-0">
                                <button type="button" class="button is-primary is-light is-small is-rounded "
                                  @click="addWorkingIntervalMeth('saturday')">
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="obtainAvailabilityConfigWorkingIntervals('saturday').length == 0">
                            <td class="has-text-centered mx-0 px-0" colspan="4">{{ $t('General.Unavailable') }}</td>
                          </tr>
                          <tr class="is-spaced"
                            v-for="workingInterval of obtainAvailabilityConfigWorkingIntervals('saturday')"
                            :key="workingInterval.pos">
                            <WorkingIntervalTableRow :interval-data="workingInterval" @changes-made="flagChangesMade(true)">
                            </WorkingIntervalTableRow>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="column">
                      <table class="table is-narrow is-fullwidth">
                        <thead>
                          <tr>
                            <th class="has-text-centered mx-0 px-0" colspan="3">{{ $t('General.Sunday') }}</th>
                            <th class="mx-0 px-0">
                              <div class="is-flex is-justify-content-start mx-0 px-0">
                                <button type="button" class="button is-primary is-light is-small is-rounded "
                                  @click="addWorkingIntervalMeth('sunday')">
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="obtainAvailabilityConfigWorkingIntervals('sunday').length == 0">
                            <td class="has-text-centered mx-0 px-0" colspan="4">{{ $t('General.Unavailable') }}</td>
                          </tr>
                          <tr class="is-spaced"
                            v-for="workingInterval of obtainAvailabilityConfigWorkingIntervals('sunday')"
                            :key="workingInterval.pos">
                            <WorkingIntervalTableRow :interval-data="workingInterval" @changes-made="flagChangesMade(true)">
                            </WorkingIntervalTableRow>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="field is-grouped is-grouped-right">
                  <p class="control">
                    <button type="submit" class="button is-primary">{{ $t('General.Save') }}</button>
                  </p>
                  <p class="control">
                    <a @click="cancelAvailabilityConfig" class="button is-light">
                      {{ $t('General.Cancel') }}
                    </a>
                  </p>
                </div>
              </form>
              <div v-if="getVetMode == 'availabilityslots'">
                <iframe :src="iframeSrc" width="100%" height="600">
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Navbar from "../components/Navbar.vue";
import { Notyf } from "notyf";
import UserTableRow from "../components/UserTableRow.vue";
import WorkingIntervalTableRow from "../components/WorkingIntervalTableRow.vue";
import VetRecordTableRow from "../components/VetRecordTableRow.vue";
import AppointmentDataForm from "../components/AppointmentDataForm.vue";


interface WorkingInterval {
  start: string;
  end: string;
}

interface DaySchedule {
  dayOfWeek: string;
  workingIntervals: WorkingInterval[];
}

interface Overlap {
  dayOfWeek: string;
  interval1: WorkingInterval;
  interval2: WorkingInterval;
}

interface VetAurinkoProfile {
    vet_id: string;
    month_nr: number;
    month: string;
    aurinko_profile_id: string;
}

interface VetAppointment {
    appointment_id: number,
    schedule: string,
    customer_user_id: number,
    customer_name: string,
    pet_id: number,
    pet_name: string,
    vet_user_id: number,
    description: string,
    price: number,
    customer_pin: number,
    vet_pin: number,
    appointment_status: string,
    status_date: string
}

const notyf = new Notyf()

export default defineComponent({
  name: "Vet",
  components: { UserTableRow, WorkingIntervalTableRow, Navbar, VetRecordTableRow, AppointmentDataForm },

  data() {
    return {
      componentName: "Vet",
      editingAvailabilityConfig: false,
      aurinko_profile_id: 0,
      changesMade: false,
      filterStartDate: '',
      filterEndDate: ''
    }
  },
  computed:
  {
    ...mapGetters(['userData', 'getEditedVetData', 'getUsers', 'getVetMode', 'getEditedAvailabilityConfigData', 'getTimeLimits', 'getAurinkoProfiles',
      'getEditedDaySchedules', 'getHasAssociatedCalendar', 'getAccessType', 'getVetRecord', 'getShowingAppointmentData']),

      iframeSrc()
      {
        // Generate the URL dynamically based on the current mode and other relevant data
        const baseUrl = process.env.VUE_APP_AURINKO_URL;
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
        const nextMonth = currentMonth +1 ;
        const profile = `profile=AvailabilityConfig_Vet_${process.env.CLIENT_DESIGNATION}_${this.getEditedVetData.vet_id}_Month_${currentMonth}`;
        const extensionProfile = `extensionProfile=AvailabilityConfig_Vet_${process.env.CLIENT_DESIGNATION}_${this.getEditedVetData.vet_id}_Month_${nextMonth}`;
        const email = `email=${this.userData.email}`;
        const name = `name=${this.userData.name}`;
        const layout = "layout=month";
        const mode = "mode=view";

        return `${baseUrl}${profile}&${extensionProfile}&${email}&${name}&${layout}&${mode}`;
      },
      
      filteredAppointments(): VetAppointment[]
      {
        if (!this.filterStartDate && !this.filterEndDate)
        {
          return this.getVetRecord as VetAppointment[];
        }
        return (this.getVetRecord as VetAppointment[]).filter((appointment: VetAppointment) =>
        {
          const appointmentDate = new Date(appointment.schedule);
          const start = this.filterStartDate ? new Date(this.filterStartDate) : null;
          const end = this.filterEndDate ? new Date(this.filterEndDate) : null;
          return (!start || appointmentDate >= start) && (!end || appointmentDate <= end);
        });
      },
      // Compute maxDate as today plus one month
      maxDate(): Date
      {
        const today = new Date();
        const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
        return nextMonth;
      }
  },
  
  methods: {
    ...mapActions(['fetchUsers', 'createUser', 'deleteUser', 'iniCustDetailing', 'toggleShowingUserData', 'toggleCustDetailing',
      'iniEditedVet', 'iniEditedAvailabilityConfig', 'iniAvailabilityConfigCreation', 'updateEditedDaySchedules',
      'iniEditedAvailabilityConfigTimeLimits', 'updateVetAvailabilityProfile', 'fetchVetAurinkoProfiles', 'toggleShowingPetRecord',
      'iniVetAppointments', 'iniVetRecord']),

    /*clearFormAvailabilityConfig() {
      this.iniEditedAvailabilityConfig()

    },*/


    async clearFormAvailabilityConfig() {
      await this.iniEditedAvailabilityConfig({ vetId: this.getEditedVetData.vet_id, aurinkoProfileId: this.aurinko_profile_id });
      console.log(this.getEditedAvailabilityConfigData);
    },

    toggleEditingAvailabilityConfig() {
      this.editingAvailabilityConfig = !this.editingAvailabilityConfig
    },

    iniConfigEditing() {
      this.clearFormAvailabilityConfig();
      this.toggleEditingAvailabilityConfig();
    },

    showCustomerData(userData: any) {
      this.iniCustDetailing(userData);
      this.toggleShowingUserData(false);
      this.toggleCustDetailing(false);
      const language = this.$i18n.locale;
      const accessType = this.getAccessType;
      this.$router.push({ path: "/customerdata", query: { lang: language, access: accessType } });
    },

    addWorkingIntervalMeth(dayOfWeek: string) {
      console.log("addWorkingIntervalMeth")
      console.log(dayOfWeek);
      this.flagChangesMade(true);
      var editedDaySchedules = this.getEditedDaySchedules; // Shallow copy.
      var dayWorkingIntervals = [] as any[]
      var dayOfWeekFound = false
      editedDaySchedules.forEach((daySchedule: any) => {
          if (daySchedule.dayOfWeek == dayOfWeek) {
            dayOfWeekFound = true
            dayWorkingIntervals = daySchedule.workingIntervals
          }
        })
      if (dayWorkingIntervals.length == 0)
      {
        // workingInterval.dayOfWeek needed for removing interval 
        dayWorkingIntervals = [{ "start": "09:00", "end": "17:00", "dayOfWeek" : dayOfWeek }]
        if(!dayOfWeekFound)
        {
          editedDaySchedules.push({"dayOfWeek": dayOfWeek, dayWorkingIntervals})
        }
      }
      else
      {
        var newInterval = {}
        var currentAvalabilityFinishingTime = new Date(`1970-01-01 00:00`)
        dayWorkingIntervals.forEach((workingInterval: any) => {
          var workingIntervalFinishingTime = new Date(`1970-01-01 ${workingInterval.end}`)
          if (workingIntervalFinishingTime > currentAvalabilityFinishingTime) {
            currentAvalabilityFinishingTime = workingIntervalFinishingTime;
          }
        })
        if (currentAvalabilityFinishingTime.getHours() == 23) {
          return //doesn't allow the addition of more intervals
        }
        var newIntervalStartingTime = new Date(`1970-01-01 00:00`);
        newIntervalStartingTime.setHours(currentAvalabilityFinishingTime.getHours() + 1);
        const newIntervalStartingTimeHours = newIntervalStartingTime.getHours().toString().padStart(2, '0');
        const newIntervalStartingTimeMinutes = newIntervalStartingTime.getMinutes().toString().padStart(2, '0');
        const newIntervalStartingTimeStr = newIntervalStartingTimeHours + ':' + newIntervalStartingTimeMinutes

        var newIntervalEndingTime = new Date(`1970-01-01 00:00`);

        newIntervalEndingTime.setHours(currentAvalabilityFinishingTime.getHours() + 2);
        const newIntervalEndingTimeHours = newIntervalEndingTime.getHours().toString().padStart(2, '0');

        var newIntervalEndingTimeMinutes: string;

        if (currentAvalabilityFinishingTime.getHours() < 22) {
          newIntervalEndingTimeMinutes = newIntervalEndingTime.getMinutes().toString().padStart(2, '0');
        }
        else {
          newIntervalEndingTimeMinutes = "00";

        }
        const newIntervalEndingTimeStr = newIntervalEndingTimeHours + ':' + newIntervalEndingTimeMinutes
        // newInterval.dayOfWeek needed for removing interval 
        newInterval = { "start": newIntervalStartingTimeStr, "end": newIntervalEndingTimeStr, "dayOfWeek" : dayOfWeek };
        console.log(newInterval)
        dayWorkingIntervals.push(newInterval)
        console.log(dayWorkingIntervals)
      }
      
      editedDaySchedules.forEach((daySchedule: any) => {
          if (daySchedule.dayOfWeek == dayOfWeek) {
            daySchedule.workingIntervals = dayWorkingIntervals
          }
        })
      console.log(editedDaySchedules)
      this.updateEditedDaySchedules(editedDaySchedules);
    },

    obtainAvailabilityConfigWorkingIntervals(dayOfWeek: string): any[] {
      var availabilityConfigWorkingInterval: any;
      var availabilityConfigWorkingIntervals = [] as any[];
      console.log("obtainAvailabilityConfigWorkingIntervals")
      console.log(dayOfWeek)
      this.getEditedDaySchedules.forEach((daySchedule: any) => {
        if (daySchedule.dayOfWeek == dayOfWeek) {
          var intervalPos = 0;
          daySchedule.workingIntervals.forEach((workingInterval: any) => {
            availabilityConfigWorkingInterval = workingInterval
            availabilityConfigWorkingInterval.pos = intervalPos;
            availabilityConfigWorkingInterval.start = this.convertTimeFormat(workingInterval.start);
            availabilityConfigWorkingInterval.end = this.convertTimeFormat(workingInterval.end);
            // availabilityConfigWorkingInterval.dayOfWeek needed for removing interval 
            availabilityConfigWorkingInterval.dayOfWeek = dayOfWeek;
            intervalPos++;
            availabilityConfigWorkingIntervals.push(availabilityConfigWorkingInterval);
          })
        }
      })
      console.log(availabilityConfigWorkingIntervals)
      return (availabilityConfigWorkingIntervals)
    },

    convertTimeFormat(timeString: string) {
      // Split the time string by ":"
      const parts = timeString.split(":");

      // Take the first two parts (hours and minutes)
      const hours = parts[0];
      const minutes = parts[1];

      // Concatenate hours and minutes with a ":" separator
      const formattedTime = hours + ":" + minutes;

      return formattedTime;
    },

    validateWorkingIntervals(): Overlap[] {
      const daySchedules: DaySchedule[] = this.getEditedDaySchedules;
      const overlaps: Overlap[] = [];

      daySchedules.forEach(daySchedule => {
        const workingIntervals = daySchedule.workingIntervals;

        for (let i = 0; i < workingIntervals.length - 1; i++) {
          const interval1 = workingIntervals[i];

          for (let j = i + 1; j < workingIntervals.length; j++) {
            const interval2 = workingIntervals[j];

            if (
              (interval1.start < interval2.end && interval1.end > interval2.start) ||
              (interval2.start < interval1.end && interval2.end > interval1.start)
            ) {
              overlaps.push({
                dayOfWeek: daySchedule.dayOfWeek,
                interval1,
                interval2
              });
            }
          }
        }
      });

      return overlaps;
    },

    async editAvailabilityConfigMeth() {
      const overlaps: Overlap[] = this.validateWorkingIntervals();

      if (overlaps.length > 0) {
        console.error("Overlaps detected:", overlaps);
        notyf.error(this.$t('Vet.OverlapWorkingIntervals'));
        return;
      }
      await this.updateVetAvailabilityProfile({vetId: this.getEditedVetData.vet_id, updatedAvailabilityProfile : this.getEditedAvailabilityConfigData})
      this.flagChangesMade(false);
      await this.iniEditedVet(this.userData.email);
      notyf.success(this.$t('Vet.AvailabilityUpdated'));
    },


    async cancelAvailabilityConfig() {
      await this.iniEditedVet(this.userData.email);
      this.flagChangesMade(false);
      notyf.success(this.$t('Vet.AvailabilityConfigCanceled'));
    },

    confirmMonthlyProfileChange(newProfileId: number, $t : any) {
      if (this.changesMade) {
        if (!confirm($t && $t('Vet.ConfirmAvailMonthChange'))) {
          // Revert the profile selection back to the previous one
          this.aurinko_profile_id = this.getEditedAvailabilityConfigData.id;
          return;
        }
      }
      // Proceed with the profile change
      this.flagChangesMade(false);
      this.iniEditedAvailabilityConfig({ vetId: this.getEditedVetData.vet_id, aurinkoProfileId: newProfileId });
    },

    flagChangesMade(changesMade: boolean)
    {
      this.changesMade = changesMade;
    },
    resetDateRange()
    {
      // Set filter dates to 15 days before and after today
      const today = new Date();
      const fifteenDays = 15;

      // Start date: 15 days before today
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - fifteenDays);
      this.filterStartDate = startDate.toISOString().split('T')[0]; // Format as yyyy-MM-dd

      // End date: 15 days after today
      const endDate = new Date(today);
      endDate.setDate(today.getDate() + fifteenDays);
      this.filterEndDate = endDate.toISOString().split('T')[0]; // Format as yyyy-MM-dd
    },
  },

  watch:
  {
    getAurinkoProfiles:
    {
      handler(newProfiles)
      {
        // Check if the profiles data has been loaded
        if (newProfiles && newProfiles.length > 0) {
          // Find the current month's profile ID and set it as the default selection
          const currentDate = new Date();
          const currentMonth = (currentDate.getMonth() + 1); // Months are 0-indexed
          console.log("currentMonth");
          const currentProfile = newProfiles.find((profile : VetAurinkoProfile) => profile.month_nr === currentMonth);
          if (currentProfile) {
            this.aurinko_profile_id = currentProfile.aurinko_profile_id;
          }
          // Now that the default profile ID has been set, initialize the edited availability config
          this.iniEditedAvailabilityConfig({ vetId: this.getEditedVetData.vet_id, aurinkoProfileId: this.aurinko_profile_id });
        }
      },
      immediate: true, // Trigger the watcher immediately upon component mount
      deep: true // Deep watch for changes in nested data
    }
  },
  mounted() {},
  
  async created() {
    this.toggleShowingUserData(true);
    this.toggleShowingPetRecord(true);
    this.toggleCustDetailing(true);
    console.log("created hook");
    await this.iniEditedVet(this.userData.email);
    console.log(this.getEditedVetData);
    console.log(this.getAurinkoProfiles);
    const language = this.$i18n.locale;
    if (this.getHasAssociatedCalendar)
    {
      await this.iniEditedAvailabilityConfigTimeLimits();
    }
    else
    {
      const accessType = this.getAccessType;
      this.toggleShowingUserData(false);
      console.log(accessType);
      this.$router.push({ path: "/vetdata", query: { lang: language, access: accessType } });
    }
    const iniVetRecordParams = {
          vetId: this.getEditedVetData.user_id,
          language: language
        };
    await this.iniVetRecord(iniVetRecordParams);
    // Set initial filter dates
    this.resetDateRange();
    console.log("end of created hook");
  }
})
</script>

<style scoped>
.content-body {
  margin-top: 10px;
}
.availability-config-box {
  border-radius: 5px;
  padding: 10px;
  overflow-x: auto; /* Enable horizontal scrolling if content overflows */
  overflow-y: hidden; /* Ensure vertical scrolling is disabled */
}

/* Manually set overflow-x for Bulma box */
.availability-config-box {
  overflow-x: auto !important;
}
</style>