import { lang } from "moment-timezone";

interface Manager {
    user_id: number,
    name: string
    email: string,
    password: string,
    confirmation: string,
    type: string
}
interface MngAppointmentRecord {
    appointment_id: number,
    schedule: string,
    vet_user_id: number,
    vet_name: string,
    customer_user_id: number,
    customer_name: string,
    pet_id: number,
    pet_name: string,
    description: string,
    appointment_status: string,
    status_date: string
}

const managerResetState = () => ({
    managementMode: 'vets',
    customerDetailing: false,
    appointmentDetailing: false,
    mngAppointmentsRecord: [] as MngAppointmentRecord []

});

export const manager = {
    state: managerResetState(),
    mutations: {
        setMngMode(state: any, userMngMode: string) {
            state.managementMode = userMngMode;
        },
        setCustDetailing(state: any, custDetailing: boolean) {
            state.customerDetailing = custDetailing;
        },
        setAppointmentDetailing(state: any, appointmentDetailing: boolean) {
            state.appointmentDetailing = appointmentDetailing;
        },
        setMngAppointmentsRecord(state: any, appointmentsRecord: MngAppointmentRecord[]) {
            console.log("setMngAppointmentsRecord");
            console.log(appointmentsRecord);
            console.log(typeof (appointmentsRecord));
            state.mngAppointmentsRecord = [];
            appointmentsRecord.forEach((appointmentrecord: any) => {
                console.log(appointmentrecord);
                state.mngAppointmentsRecord.push(appointmentrecord)
            });
        },
        managerResetStateMut(state: any) {
            Object.assign(state, managerResetState())
        }
    },
    getters: {
        getMngMode(state: any): string {
            return (state.managementMode);
        },
        getCustDetailing(state: any): boolean {
            return (state.customerDetailing);
        },
        getAppointmentDetailing(state: any): boolean {
            return (state.appointmentDetailing);
        },
        getMngAppointmentsRecord(state: any): MngAppointmentRecord[] {
            return (state.mngAppointmentsRecord);
        }
    },
    actions: {
        iniVetsManagement(state: any) {
            state.commit('setMngMode', "vets");
        },
        iniManagersManagement(state: any) {
            state.commit('setMngMode', "managers");
        },
        iniCustomersManagement(state: any) {
            state.commit('setMngMode', "customers");
        },
        iniAppointmentsManagement(state: any) {
            state.commit('setMngMode', "appointments");
        },
        iniProductsManagement(state: any) {
            state.commit('setMngMode', "products");
        },
        toggleCustDetailing(state: any, custDetailing: boolean) {
            state.commit('setCustDetailing', !custDetailing)
        },
        toggleAppointmentDetailing(state: any, appointmentDetailing: boolean) {
            state.commit('setAppointmentDetailing', !appointmentDetailing)
        },
        
        async iniMngAppointmentsRecord(state : any, language : string) {
            console.log("iniMngAppointmentsRecord");
            console.log(language);
            console.log(process.env.BASE_URL + `api/appointments/mngrecord/` + language);

            return (new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/appointments/mngrecord/` + language,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then((res) => res.json())
                    .then((appointments_record_data) => {
                        console.log(appointments_record_data);
                        console.log(typeof (appointments_record_data));
                        state.commit('setMngAppointmentsRecord', appointments_record_data)
                        resolve(appointments_record_data)
                    })
                    .catch(err => reject(err))
            }))
        }

    }
}

const editedManagerResetState = () => ({
    editedManagerData: {
        user_id: 0,
        name: '',
        email: '',
        password: '',
        confirmation: '',
        type: 'manager',
    },
    editingManager: false,
});

export const editedManager = {
    state: editedManagerResetState(),

    getters: {
        getEditedManagerData(state: any): Manager {
            return (state.editedManagerData)
        },
        getEditingManager(state: any): boolean {
            return (state.editingManager)
        },
    },
    mutations: {
        setEditedUserData(state: any, editedUserData: any) {
            state.editedManagerData.user_id = editedUserData.user_id;
            state.editedManagerData.email = editedUserData.email;
            state.editedManagerData.name = editedUserData.name;
        },

        setEditedManagerData(state: any, editedManagerData: any) {
            state.editedManagerData = editedManagerData
        },
        setEditingManager(state: any, editingManager: boolean) {
            state.editingManager = editingManager
        },
        editedManagerResetStateMut(state: any) {
            Object.assign(state, editedManagerResetState())
        }
    },
    actions: {

        async iniEditedManager(state: any, managerEmail: string) {

            console.log("iniEditedManager")

            try {
                const response = await fetch(process.env.BASE_URL + `api/users/manager/byemail/${managerEmail}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch manager data');
                }

                const managerData = await response.json();
                console.log(managerData);
                console.log(managerData.user_id);
                state.commit('setEditedManagerData', managerData);
                console.log(managerData.email);

                return managerData; // Resolve the promise with the fetched vet data
            } catch (error) {
                console.error('Error fetching manager data:', error);
                throw error; // Re-throw the error to be caught by the caller
            }

        },

        async iniManagerDetailing(state: any, managerData: any) {

            console.log("iniManagerDetailing")

            console.log(managerData);
            console.log(managerData.user_id);
            state.commit('setEditedUserData', managerData)
        },


        iniManagerEditing(state: any) {

            state.commit('setEditingManager', true);
        },

        endManagerEditing(state: any) {

            state.commit('setEditingManager', false);
        },

        async editManager(state: any, editedManagerData: Manager) {
            console.log(JSON.stringify(editedManagerData))
            const response = await fetch(process.env.BASE_URL + `api/users/manager/` + editedManagerData.user_id.toString(), {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
                body: JSON.stringify(editedManagerData),
            })

            var editedManagerId: number = 0

            if (response.ok) {
                const resjson = await response.json()
                editedManagerId = resjson.user_id

            } else {
                throw new Error('error_updating_manager')
            }
            return (editedManagerId)
        },
    }
}

