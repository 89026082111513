import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n} from 'vue-i18n';

// fontawesome
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min'

// bulma
import 'bulma/css/bulma.min.css'

// flatpickr
import 'flatpickr/dist/flatpickr.min.css'

// notyf
import 'notyf/notyf.min.css';

// Vue Date Picker
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css'; // Import the CSS for floating-vue

//Previous version
//createApp(App).use(store).use(router).component('VueDatePicker', VueDatePicker).mount('#app');

// Configure Vue I18n
const i18n = createI18n({
  legacy: false,
  locale: process.env.APP_DEFAULT_LANGUAGE, // default locale
  messages: {}
});

// Create Vue app
const app = createApp(App);

// Fetch translations from backend controller
async function fetchTranslations(lang: string) {
  const baseUrl = process.env.BASE_URL;
  const response = await fetch(`${baseUrl}api/translations/${lang}`);
  if (!response.ok) {
    throw new Error('Failed to fetch translations');
  }
  return response.json();
}

// Fetch and set initial locale
fetchTranslations(process.env.APP_DEFAULT_LANGUAGE).then(messages => {
  i18n.global.setLocaleMessage(process.env.APP_DEFAULT_LANGUAGE, messages);
  app.use(store)
     .use(router)
     .use(i18n)
     .use(FloatingVue) // Register FloatingVue plugin
     .component('VueDatePicker', VueDatePicker)
     .mount('#app');
}).catch(error => {
  console.error('Failed to fetch translations:', error);
});

// Function to change locale
async function changeLocale(lang: string) {
  try {
    const messages = await fetchTranslations(lang);
    i18n.global.setLocaleMessage(lang, messages);
    i18n.global.locale.value = lang;
  } catch (error) {
    console.error('Failed to fetch translations:', error);
  }
}

export { changeLocale };