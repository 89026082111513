import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-445cc220"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "hero-body" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "content-body" }
const _hoisted_6 = { class: "header" }
const _hoisted_7 = { class: "title is-5" }
const _hoisted_8 = { class: "columns" }
const _hoisted_9 = { class: "column" }
const _hoisted_10 = { class: "field" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = { class: "control" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "column" }
const _hoisted_15 = { class: "field" }
const _hoisted_16 = { class: "label" }
const _hoisted_17 = { class: "control" }
const _hoisted_18 = ["placeholder"]
const _hoisted_19 = { class: "column" }
const _hoisted_20 = { class: "field" }
const _hoisted_21 = { class: "label" }
const _hoisted_22 = { class: "control" }
const _hoisted_23 = ["placeholder"]
const _hoisted_24 = { class: "columns" }
const _hoisted_25 = { class: "column" }
const _hoisted_26 = { class: "field" }
const _hoisted_27 = { class: "label" }
const _hoisted_28 = { class: "control" }
const _hoisted_29 = ["placeholder"]
const _hoisted_30 = { class: "column" }
const _hoisted_31 = { class: "field" }
const _hoisted_32 = { class: "label" }
const _hoisted_33 = { class: "control" }
const _hoisted_34 = ["placeholder"]
const _hoisted_35 = { class: "column" }
const _hoisted_36 = { class: "field" }
const _hoisted_37 = { class: "label" }
const _hoisted_38 = { class: "control" }
const _hoisted_39 = ["placeholder"]
const _hoisted_40 = { class: "columns" }
const _hoisted_41 = { class: "column" }
const _hoisted_42 = { class: "field" }
const _hoisted_43 = { class: "label" }
const _hoisted_44 = { class: "control" }
const _hoisted_45 = ["placeholder"]
const _hoisted_46 = { class: "column" }
const _hoisted_47 = { class: "field" }
const _hoisted_48 = { class: "label" }
const _hoisted_49 = { class: "control" }
const _hoisted_50 = ["placeholder"]
const _hoisted_51 = { class: "content-body" }
const _hoisted_52 = { class: "field is-grouped is-grouped-right" }
const _hoisted_53 = {
  key: 0,
  class: "control"
}
const _hoisted_54 = {
  type: "submit",
  class: "button is-primary"
}
const _hoisted_55 = {
  key: 1,
  class: "control"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Navbar),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("form", {
              onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createCustomerMeth && _ctx.createCustomerMeth(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("form", {
                  onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createCustomerMeth && _ctx.createCustomerMeth(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.$t('CustomerData.PleaseEnterYourCustomerData')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('CustomerData.Name')), 1),
                        _createElementVNode("div", _hoisted_12, [
                          _withDirectives(_createElementVNode("input", {
                            minlength: 2,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getNewCustomerData.name) = $event)),
                            class: "input",
                            type: "text",
                            placeholder: _ctx.$t('CustomerData.NamePlaceholder'),
                            required: ""
                          }, null, 8, _hoisted_13), [
                            [_vModelText, _ctx.getNewCustomerData.name]
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('CustomerData.Email')), 1),
                        _createElementVNode("div", _hoisted_17, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getNewCustomerData.email) = $event)),
                            class: "input",
                            type: "email",
                            placeholder: _ctx.replaceSpecialCharacters(_ctx.$t('CustomerData.EmailPlaceholder')),
                            required: ""
                          }, null, 8, _hoisted_18), [
                            [_vModelText, _ctx.getNewCustomerData.email]
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('UserData.PhoneNumber')), 1),
                        _createElementVNode("div", _hoisted_22, [
                          _withDirectives(_createElementVNode("input", {
                            minlength: 6,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getNewCustomerData.phone) = $event)),
                            class: "input",
                            type: "tel",
                            placeholder: _ctx.$t('UserData.PhoneNumberPlaceholder'),
                            required: ""
                          }, null, 8, _hoisted_23), [
                            [_vModelText, _ctx.getNewCustomerData.phone]
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('CustomerData.VATNumber')), 1),
                        _createElementVNode("div", _hoisted_28, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.getNewCustomerData.tax_id) = $event)),
                            class: "input",
                            type: "text",
                            placeholder: _ctx.$t('CustomerData.VATNumberPlaceholder'),
                            required: ""
                          }, null, 8, _hoisted_29), [
                            [_vModelText, _ctx.getNewCustomerData.tax_id]
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('CustomerData.Password')), 1),
                        _createElementVNode("div", _hoisted_33, [
                          _withDirectives(_createElementVNode("input", {
                            minlength: 6,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.getNewCustomerData.password) = $event)),
                            class: "input",
                            type: "password",
                            placeholder: _ctx.$t('CustomerData.PasswordPlaceholder'),
                            required: ""
                          }, null, 8, _hoisted_34), [
                            [_vModelText, _ctx.getNewCustomerData.password]
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.$t('CustomerData.PassConfirmation')), 1),
                        _createElementVNode("div", _hoisted_38, [
                          _withDirectives(_createElementVNode("input", {
                            minlength: 6,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.getNewCustomerData.confirmation) = $event)),
                            class: "input",
                            type: "password",
                            placeholder: _ctx.$t('CustomerData.PassConfirmationPlaceholder'),
                            required: ""
                          }, null, 8, _hoisted_39), [
                            [_vModelText, _ctx.getNewCustomerData.confirmation]
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("div", _hoisted_41, [
                      _createElementVNode("div", _hoisted_42, [
                        _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.$t('CustomerData.Address')), 1),
                        _createElementVNode("div", _hoisted_44, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.getNewCustomerData.address) = $event)),
                            class: "input",
                            type: "text",
                            placeholder: _ctx.$t('CustomerData.AddressPlaceholder'),
                            required: ""
                          }, null, 8, _hoisted_45), [
                            [_vModelText, _ctx.getNewCustomerData.address]
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("div", _hoisted_47, [
                        _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.$t('CustomerData.VetCenter')), 1),
                        _createElementVNode("div", _hoisted_49, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.getNewCustomerData.vet_center) = $event)),
                            class: "input",
                            type: "email",
                            placeholder: _ctx.$t('CustomerData.VetCenterPlaceholder'),
                            required: ""
                          }, null, 8, _hoisted_50), [
                            [_vModelText, _ctx.getNewCustomerData.vet_center]
                          ])
                        ])
                      ])
                    ])
                  ])
                ], 32)
              ]),
              _createElementVNode("div", _hoisted_51, [
                _createElementVNode("div", _hoisted_52, [
                  (!_ctx.addingPet && !_ctx.getEditingPet)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_53, [
                        _createElementVNode("button", _hoisted_54, _toDisplayString(_ctx.$t('General.Save')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.addingPet && !_ctx.getEditingPet)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_55, [
                        _createElementVNode("a", {
                          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.cancelRegistration && _ctx.cancelRegistration(...args))),
                          class: "button is-light"
                        }, _toDisplayString(_ctx.$t('General.Cancel')), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ], 32)
          ])
        ])
      ])
    ])
  ]))
}