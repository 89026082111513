import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fbf1462"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-file-input mt-0" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = {
  class: "control",
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_4 = {
  key: 0,
  class: "help",
  style: {"margin-left":"10px"}
}
const _hoisted_5 = {
  key: 1,
  class: "help",
  style: {"margin-left":"10px"}
}
const _hoisted_6 = { class: "help" }
const _hoisted_7 = {
  href: "https://wetransfer.com/",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('FilesData.Files')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.triggerFileInput && _ctx.triggerFileInput(...args))),
        type: "button",
        class: "button is-primary"
      }, _toDisplayString(_ctx.$t('FilesData.ChooseFiles')), 1),
      _createElementVNode("input", {
        type: "file",
        ref: "fileInput",
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args))),
        style: {"display":"none"},
        multiple: ""
      }, null, 544),
      (_ctx.selectedFiles.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('Customer.NoFilesAttached')), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.fileNames), 1))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_6, [
        _createTextVNode(_toDisplayString(_ctx.$t('FilesData.MaxTotFilesSizePrev')) + " ", 1),
        _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t('FilesData.MaxTotFilesSizeLink')), 1),
        _createTextVNode(_toDisplayString(_ctx.$t('FilesData.MaxTotFilesSizeNext')), 1)
      ])
    ])
  ]))
}