interface User {
    user_id: number,
    name: string
}

interface Pet {
    pet_id: number,
    user_id: number,
    name: string
    species: string,
    breed: string,
    gender: string,
    birth: Date,
    fertile_status: string,
    weight: number,
}

interface Customer {
    user_id: number,
    name: string
    email: string,
    password: string,
    confirmation: string,
    type: string,
    phone: string,
    address: string,
    tax_id: string,
    vet_center: string,
    pets: Pet[],
}

interface Breed {
    breed_id: number,
    name: string
}



interface UserNameParams {
    user_id: number;
    name: string;
}

interface UserEmailParams {
    user_id: number;
    email: string;
}

interface CustomerPhoneParams {
    user_id: number;
    name: string;
}

interface CustomerWhatsAppParams {
    user_id: number;
    name: string;
}
interface LoginData {
    email: string;
    password: string;
    type: string;
}

interface UserImageParams {
    user_id: number,
    file: string,
}

interface UpdateBreedsListParams {
    species: string,
    language: string,
}

const customerRegResetState = () => ({
    newCustomerData: {
        user_id: 0,
        name: '',
        email: '',
        password: '',
        confirmation: '',
        type: 'customer',
        phone: '',
        address: '',
        tax_id: '',
        vet_center: ''
    },
    pets: [] as Pet[],
    newPetData: {
        pet_id: 0,
        user_id: 0,
        name: '',
        species: '',
        breed: '',
        gender: '',
        birth: '',
        fertile_status: '',
        weight: 0,
    },
    breeds: [] as Breed[],
    editingPet: false,
});


export const newCustomer = {
    state: customerRegResetState(),

    getters: {
        getNewCustomerData(state: any): Customer {
            return (state.newCustomerData)
        },
        getNewCustomerPets(state: any): Pet[] {
            return (state.pets)
        },
        getNewPetData(state: any): Pet {
            return (state.newPetData)
        },
        getBreeds(state: any): Breed[] {
            return (state.breeds)
        },
        getEditingPet(state: any): boolean {
            return (state.editingPet)
        },
    },
    mutations: {
        setNewCustomerData(state: any, newCustomerData: any) {
            state.newCustomerData = newCustomerData
        },
        setAddPet(state: any, newPet: any) {
            state.pets.push(newPet)
        },

        setEditPet(state: any, editedPet: any) {
            state.pets[editedPet.pet_id] = editedPet
        },

        setRemovePet(state: any, petIdx: any) {
            state.pets.splice(petIdx, 1)
        },

        setAdjustIdsPets(state: any) {
            for (var petIdx in state.pets) {
                state.pets[petIdx].pet_id = petIdx
            }
        },

        setIniPet(state: any) {
            state.newPetData = {
                pet_id: 0,
                user_id: 0,
                name: '',
                species: '',
                breed: '',
                gender: '',
                birth: '',
                fertile_status: '',
                weight: 0,
            }
        },

        setIniCustomer(state: any) {
            state.newCustomerData = {
                user_id: 0,
                name: '',
                email: '',
                password: '',
                confirmation: '',
                type: 'customer',
                phone: '',
                address: '',
                tax_id: '',
                vet_center: '',
            }
            state.pets = []
        },

        setBreeds(state: any, breeds: Breed[]) {
            state.breeds = breeds
        },

        setIniBreed(state: any) {
            state.newPetData.breed = ''
        },

        setIniBreeds(state: any) {
            state.breeds = []
        },
        setEditingPet(state: any, editingPet: boolean) {
            state.editingPet = editingPet
        },
        setPetNewData(state: any, petNewData: Pet) {
            state.newPetData.pet_id = petNewData.pet_id
            state.newPetData.user_id = petNewData.user_id
            state.newPetData.name = petNewData.name
            state.newPetData.species = petNewData.species
            state.newPetData.breed = petNewData.breed
            state.newPetData.gender = petNewData.gender
            state.newPetData.birth = petNewData.birth
            state.newPetData.fertile_status = petNewData.fertile_status
            state.newPetData.weight = petNewData.weight
        },
        
        customerRegResetStateMut(state: any) {
            Object.assign(state, customerRegResetState())
        }
    },
    actions: {
        async addPet(state: any, newPet: Pet) {

            console.log(newPet)
            state.commit('setAddPet', newPet)

        },

        async editPet(state: any, editedPet: Pet) {

            console.log(editedPet)
            state.commit('setEditPet', editedPet)

        },

        async removePet(state: any, petIdx: number) {
            console.log(petIdx)
            state.commit('setRemovePet', petIdx)
            console.log(state.pets)
            state.commit('setAdjustIdsPets', petIdx)
            console.log(state.pets)
        },

        async iniNewPet(state: any) {
            state.commit('setIniPet')
        },

        async iniNewCustomer(state: any) {
            state.commit('setIniCustomer')
        },

        async createCustomer(state: any, newCustomerData: Customer) {
            console.log(JSON.stringify(newCustomerData))
            const response = await fetch(process.env.BASE_URL + `api/users/customer`, {
                method: 'POST',
                body: JSON.stringify(newCustomerData),
            })

            var newCustomerId: number = 0

            if (response.ok) {
                const resjson = await response.json()
                newCustomerId = resjson.user_id

            } else {
                throw new Error('error_creating_new_customer')
            }
            return (newCustomerId)
        },

        resetBreed(state: any) {
            state.commit('setIniBreed')
        },

        async updateBreedsList(state: any, params: UpdateBreedsListParams) {
            if (params.species == '' || params.species == 'Other') {
                state.commit('setIniBreeds')
            } else {
                return new Promise((resolve, reject) => {
                    fetch(process.env.BASE_URL + `api/pets/breeds/` + params.species + '/' + params.language, {
                        method: 'GET',
                    })
                        .then(res => res.json())
                        .then(breeds => {
                            state.commit('setBreeds', breeds)
                            resolve(breeds)
                        })
                        .catch(err => reject(err))
                })
            }

        },

        async createPet(state: any, newPetData: Pet) {
            console.log(JSON.stringify(newPetData))
            const response = await fetch(process.env.BASE_URL + `api/pets`, {
                method: 'POST',
                body: JSON.stringify(newPetData),
            })
            if (!response.ok) {
                throw new Error('error_creating_new_pet')
            }
        },

        async toggleEditingPet(state: any, editingPet: boolean) {
            console.log(editingPet)
            state.commit('setEditingPet', !editingPet)
        },

        async iniEditingPet(state: any, editingPetData: Pet) {
            console.log(editingPetData)
            state.commit('setPetNewData', editingPetData)
        },
    }
}
