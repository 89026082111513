<template>
  <th>
    {{ userData.user_id }}
  </th>
  <td v-if="userData.type != 'customer'">
    <figure class="image is-64x64 pointer" @click="selectFile">
      <img class="is-rounded" :src="userImageURL" alt="click_to_upload">
    </figure>
    <input type="file" class="is-hidden" ref="imageInput" @change="uploadImage($t)">
  </td>
  <td>
    <div v-if="!editing">{{ userData.name }}</div>
    <input v-else type="text" v-model="editedName" class="input">
  </td>
  <td>
    <div v-if="!editing">{{ userData.email }}</div>
    <input v-else type="text" v-model="editedEmail" class="input">
  </td>
  <td v-if="userData.type != 'manager'">
    <div v-if="!editing">{{ userData.phone }}</div>
    <input v-else type="text" v-model="editedPhone" class="input">
  </td>
  <td v-if="userData.type == 'customer'">
    <div v-if="!editing">{{ userData.address }}</div>
    <input v-else type="text" v-model="editedAddress" class="input">
  </td>
  <td v-if="userData.type == 'vet'">
    <div v-if="!editing">{{ userData.specialty }}</div>
    <input v-else type="text" v-model="editedSpecialty" class="input">
  </td>
  <td v-if="userData.type == 'customer'">
    <div v-if="!editing">{{ userData.tax_id }}</div>
    <input v-else type="text" v-model="editedTaxId" class="input">
  </td>
  <td>
    <div class="is-flex is-justify-content-start" style="gap: 1em;">
      <div v-if="userData.type != 'customer'">
        <button v-if="!editing" class="button is-warning is-small" @click="toggleEditing">{{ $t('General.Edit') }}</button>
        <button v-else class="button is-success is-small" @click="save($t)">{{ $t('General.Save') }}</button>
      </div>
      <div v-if="!editing && userData.type != 'customer'">
        <button class="button is-danger is-small" @click="remove(userData, $t)">{{ $t('General.Delete') }}</button>
      </div>
      <div v-if="userData.type == 'customer'">
        <button class="button is-success is-small" @click="parentShowCustomerData(userData)">{{ $t('General.Details') }}</button>
      </div>
    </div>
  </td>
</template>

<script lang="ts">
import { defineComponent, defineProps } from 'vue'
import { Notyf } from "notyf";

const notyf = new Notyf()

export default defineComponent({
  name: "UserTableRow"
})
</script>
<script lang="ts" setup>
import { useStore } from "vuex";
import { computed, onMounted, ref, Ref } from "vue";

const props = defineProps(['userData', 'parentShowCustomerData'])
const store = useStore()
const editing = ref(false)
const editedName = ref('')
const editedEmail = ref('')
const editedPhone = ref('')
const editedWhatsapp = ref('')
const editedAddress = ref('')
const editedSpecialty = ref('')
const editedTaxId = ref('')
const imageInput : Ref<HTMLInputElement | null> = ref(null)

const toggleEditing = () => {
  editedName.value = props.userData.name
  editedEmail.value = props.userData.email
  editedPhone.value = props.userData.phone
  editedAddress.value = props.userData.address
  editedSpecialty.value = props.userData.specialty
  editedTaxId.value = props.userData.tax_id
  editing.value = !editing.value
}

const remove = async (userData: any, $t: any) => {
  if (confirm($t && $t('UserData.ConfirmDelete'))) {
    //TODO:Create notification to ask if it is really to delete
    try {
      await store.dispatch('deleteUser', userData)
      notyf.success($t('UserData.NotyfUserRemoved'))
    } catch (e) {
      notyf.error($t('UserData.NotyfErrorRemoveUser'))
    }
  }
}

const save = async ($t: any) => {


  try {
    await store.dispatch('changeName', {
      name: editedName.value,
      user_id: props.userData.user_id,
    })

    await store.dispatch('changeEmail', {
      email: editedEmail.value,
      user_id: props.userData.user_id,
    })

    await store.dispatch('changePhone', {
      phone: editedPhone.value,
      user_id: props.userData.user_id,
      type: props.userData.type
    })

    await store.dispatch('changeSpecialty', {
      specialty: editedSpecialty.value,
      user_id: props.userData.user_id,
    })

    await store.dispatch('fetchUsers', props.userData.type)

    toggleEditing()
    notyf.success($t('UserData.NotyfDataEdited'))
  } catch (e) {
    notyf.error($t('UserData.NotyfErrorUpdatingData'))
  }

}

const userImageURL = computed(() => {
  const random = Math.round(99999 * Math.random())
  return process.env.BASE_URL + `api/users/image/${props.userData.user_id}/?hash=${random}`
})

const selectFile = () => {
  if (imageInput && imageInput.value) {
    imageInput.value.click()
  } else {
    console.error("imageInput is not defined or its value is null.")
  }
  // previous version had a ts error.
  // imageInput.value.click()
}

const uploadImage = async ($t : any) => {
  try
  {
    if (imageInput.value && imageInput.value.files && imageInput.value.files[0])
    {
      await store.dispatch('uploadUserImage', {
        user_id: props.userData.user_id,
        file: imageInput.value.files[0]
      });
  
      store.dispatch('fetchUsers', props.userData.type)
    }
    else
    {
      console.error("No file selected or imageInput is null.");
      notyf.error($t('UserData.NotyfErrorUploadingPhoto'));
    }
  } 
  catch (e)
  {
    notyf.error($t('UserData.NotyfErrorUploadingPhoto'))
  }
}

onMounted(() => {

})
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
