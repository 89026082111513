import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5deeb203"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "hero-body" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "content-body" }
const _hoisted_6 = { class: "header" }
const _hoisted_7 = {
  key: 0,
  class: "title is-5"
}
const _hoisted_8 = {
  key: 1,
  class: "title is-5"
}
const _hoisted_9 = { class: "columns is-vcentered" }
const _hoisted_10 = { class: "column is-one-fifth mb-0 is-flex is-align-items-center is-justify-content-center" }
const _hoisted_11 = { class: "image-container" }
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 0,
  style: {"text-align":"center","font-size":"12px","margin-top":"0px"}
}
const _hoisted_14 = { class: "column mb-0" }
const _hoisted_15 = { class: "field" }
const _hoisted_16 = { class: "label" }
const _hoisted_17 = { class: "control" }
const _hoisted_18 = ["placeholder", "disabled"]
const _hoisted_19 = { class: "columns" }
const _hoisted_20 = { class: "column mt-0" }
const _hoisted_21 = { class: "field" }
const _hoisted_22 = { class: "label" }
const _hoisted_23 = { class: "control" }
const _hoisted_24 = ["placeholder", "disabled"]
const _hoisted_25 = { class: "field is-grouped is-grouped-right" }
const _hoisted_26 = {
  key: 0,
  class: "control"
}
const _hoisted_27 = {
  type: "submit",
  class: "button is-primary"
}
const _hoisted_28 = {
  key: 1,
  class: "control"
}
const _hoisted_29 = {
  key: 2,
  class: "control"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Navbar),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("form", {
              onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => ((_ctx.editManagerMeth(_ctx.$t))), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_ctx.getEditingManager)
                    ? (_openBlock(), _createElementBlock("h5", _hoisted_7, _toDisplayString(_ctx.$t('UserData.PleaseEnterYourUserData')), 1))
                    : _createCommentVNode("", true),
                  (!_ctx.getEditingManager)
                    ? (_openBlock(), _createElementBlock("h5", _hoisted_8, _toDisplayString(_ctx.$t('UserData.YourUserData')), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("figure", {
                        class: "image is-128x128 pointer",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectFile && _ctx.selectFile(...args)))
                      }, [
                        _createElementVNode("img", {
                          class: "is-rounded",
                          src: _ctx.userImageURL,
                          alt: "click_to_upload"
                        }, null, 8, _hoisted_12)
                      ]),
                      (_ctx.getEditingManager)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.$t('UserData.PleaseClickPhotoToUpdate')), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("input", {
                      type: "file",
                      class: "is-hidden",
                      ref: "imageInput",
                      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.uploadImage && _ctx.uploadImage(...args)))
                    }, null, 544)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('UserData.Name')), 1),
                      _createElementVNode("div", _hoisted_17, [
                        _withDirectives(_createElementVNode("input", {
                          minlength: 2,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getEditedManagerData.name) = $event)),
                          class: "input",
                          type: "text",
                          placeholder: _ctx.$t('UserData.NamePlaceholder'),
                          required: "",
                          disabled: !_ctx.getEditingManager
                        }, null, 8, _hoisted_18), [
                          [_vModelText, _ctx.getEditedManagerData.name]
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('UserData.Email')), 1),
                      _createElementVNode("div", _hoisted_23, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.getEditedManagerData.email) = $event)),
                          class: "input",
                          type: "email",
                          placeholder: _ctx.replaceSpecialCharacters(_ctx.$t('UserData.EmailPlaceholder')),
                          required: "",
                          disabled: !_ctx.getEditingManager
                        }, null, 8, _hoisted_24), [
                          [_vModelText, _ctx.getEditedManagerData.email]
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  (_ctx.getEditingManager)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_26, [
                        _createElementVNode("button", _hoisted_27, _toDisplayString(_ctx.$t('General.Save')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.getEditingManager)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_28, [
                        _createElementVNode("a", {
                          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.cancelEditing && _ctx.cancelEditing(...args))),
                          class: "button is-light"
                        }, _toDisplayString(_ctx.$t('General.Cancel')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.getEditingManager)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_29, [
                        _createElementVNode("a", {
                          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.iniManagerEditing && _ctx.iniManagerEditing(...args))),
                          class: "button is-primary"
                        }, _toDisplayString(_ctx.$t('General.ModifyData')), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ], 32)
          ])
        ])
      ])
    ])
  ]))
}