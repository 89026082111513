<template>
  <div class="container" v-if="appointmentHash">
    <div class="video-container is-flex is-justify-content-center is-align-items-center">
      <div v-if="isChecking" class="is-flex is-flex-direction-column is-align-items-center">
        <img class="loading-logo" src="https://i.imgur.com/BvpCaxv.png" alt="wisevet_live_logo">
        <p class="subtitle">{{ $t('AppointmentData.Connecting') }}</p>
        <span class="fa fa-spinner fa-spin fa-4x"></span>
      </div>
      <div v-else>
        <JitsiIframe :appointment-hash="appointmentHash" :user-data="userData" :language="language"></JitsiIframe>
      </div>
    </div>
  </div>
  <div class="main-div" v-else>
    <div class="container">
      <div class="columns mt-4">
        <div class="column is-one-third-desktop is-offset-one-third-desktop">
          <img src="https://i.imgur.com/uA3fRLZ.png" class="image" alt="logo">
          <article class="message is-warning" v-if="showWarning">
            <div class="message-header">
              <p><span class="fas fa-exclamation-triangle"></span>{{ $t('AppointmentData.Unavailable') }}</p>
            </div>
            <div class="message-body" v-html="$t('AppointmentData.UnavailableExplanation')">
            </div>
          </article>
          <form @submit.prevent="checkPIN" class="p-3">
            <div class="field">
              <label class="label">{{ $t('AppointmentData.PIN') }}</label>
              <input type="number" class="input has-text-centered is-size-2" placeholder="xxxxxx" v-model="pin" required>
              <p class="has-text-grey-light is-size-7">{{ $t('AppointmentData.PinInstructions') }}</p>
            </div>
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <button type="button" @click="goBack">{{ $t('General.Back') }}</button>
              <button class="button is-primary" :class="{'is-loading':isCheckingPIN}">{{ $t('AppointmentData.Connect') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import JitsiIframe from "../components/JitsiIframe.vue";
import { changeLocale } from "@/main"; // Import the changeLocale function


export default defineComponent({
  name: 'Appointment',
  components: {JitsiIframe},
  data() {
    return {
      pin: '',
      isChecking: true,
      isCheckingPIN: false,
      isValidPIN: false,
      showWarning: false,
    }
  },
  computed: {
    appointmentHash() {
      return this.$store.getters.appointmentHash;
    },
    userData() {
      return {
        email: this.$store.getters.appointmentEmail,
        name: this.$store.getters.appointmentName,
      }
    },
    language() {
      return this.$route.query.lang as string;;
    },
    
  },
  methods: {
    checkPIN() {
      this.showWarning = false;
      this.isCheckingPIN = true;
      this.$store.dispatch('getAppointmentByPIN', this.pin)
          .then((appointmentData: any) => {
            this.$store.commit('appointmentEmail', appointmentData.user_email)
            this.$store.commit('appointmentName', appointmentData.user_name)
            this.$store.commit('appointmentHash', appointmentData.hash)
            this.isCheckingPIN = false;

            setTimeout(() => {
              this.isChecking = false;
            }, 2000);
          })
          .catch(() => {
            this.showWarning = true;
            this.isCheckingPIN = false;
          })
    },
    goBack() {

      this.$router.go(-1);
    }
  },

  beforeMount()
  {
    const lang = this.$route.query.lang as string;
    console.log('appointment beforeMount');

    if (lang)
    {
      try
      {
        changeLocale(lang);
      }
      catch (error)
      {
        console.error('Failed to change language:', error);
      }
    }

  },

  mounted() {
    if (this.$route.params['pin']) {
      this.pin = this.$route.params['pin'].toString()
    }
  }
})
</script>

<style scoped>

.video-container {
  height: 100vh;
}

.loading-logo {
  width: 300px;
}

.main-div {
  height: 100vh;
  display: flex;
  align-items: center;
}
</style>
