<template>
    <div class="custom-file-input mt-0">
      <label class="label">{{ $t('FilesData.Files') }}</label>
      <div class="control" style="display: flex; align-items: center;">
        <button @click="triggerFileInput" type="button" class="button is-primary">
          {{ $t('FilesData.ChooseFiles') }}
        </button>
        <input type="file" ref="fileInput" @change="handleFileChange" style="display: none" multiple>
        <div v-if="selectedFiles.length === 0" class="help" style="margin-left: 10px;">{{ $t('Customer.NoFilesAttached') }}</div>
        <div v-else class="help" style="margin-left: 10px;">{{ fileNames }}</div>
      </div>
      <div>
        <p class="help">{{ $t('FilesData.MaxTotFilesSizePrev') }} <a href="https://wetransfer.com/" target="_blank">{{ $t('FilesData.MaxTotFilesSizeLink') }}</a>{{ $t('FilesData.MaxTotFilesSizeNext') }}</p>                    
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed } from 'vue';
  
  export default defineComponent({
    name: 'FileInput',
    emits: ['fileChange'],
    setup(_, { emit }) {
      const fileInput = ref<HTMLInputElement | null>(null);
      const selectedFiles = ref<File[]>([]);
  
      const triggerFileInput = () => {
        if (fileInput.value) {
          fileInput.value.click();
        }
      };
  
      const handleFileChange = (event: Event) => {
        const input = event.target as HTMLInputElement;
        if (input.files) {
          selectedFiles.value = Array.from(input.files);
          emit('fileChange', selectedFiles.value);
        }
      };
  
      const fileNames = computed(() => selectedFiles.value.map(file => file.name).join(', '));
  
      const clear = () => {
        selectedFiles.value = []; // Clear the selected files
        if (fileInput.value) {
          fileInput.value.value = ''; // Reset the input value
        }
        emit('fileChange', selectedFiles.value); // Emit the change event with an empty array
      };
      
      return {
        fileInput,
        selectedFiles,
        triggerFileInput,
        handleFileChange,
        fileNames,
        clear
      };

    }
  });
  </script>
  
  <style scoped>
  .custom-file-input {
    margin-top: 1rem;
  }
  </style>
  