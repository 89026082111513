<template>
  <h4 class="title is-4">
    {{ $t('AppointmentData.VideoAppointment') }}
  </h4>
  <form class="column is-flex is-flex-direction-column is-justify-content-center" @submit.prevent="editAppointmentFilesMeth">
    <div class="block">
      <div class="columns">
        <div class="column is-3">
          <label class="label">{{ $t('AppointmentData.Schedule') }}</label>
          <div class="control">
            <p>{{ getEditedAppointmentData.schedule }}</p>
          </div>
        </div>
        <div class="column is-3">
          <label class="label">{{ $t('AppointmentData.Customer') }}</label>
          <div class="control">
            <p>{{ getEditedAppointmentData.customer_name }}</p>
          </div>
        </div>
        <div class="column is-3">
          <label class="label">{{ $t('AppointmentData.Pet') }}</label>
          <div class="control">
            <p>{{ getEditedAppointmentData.pet_name }}</p>
          </div>
        </div>
        <div class="column is-3">
          <label class="label">{{ $t('AppointmentData.Vet') }}</label>
          <div class="control">
            <p>{{ getEditedAppointmentData.vet_name }}</p>
          </div>
        </div>
      </div>
      <!-- Chief Complaint -->
      <div class="field" v-if="!addingFile && getEditingAppointment && getAccessType == 'customer'">
        <label class="label mt-0">{{ $t('Customer.ChiefComplaint') }}</label>
        <div class="control">
          <textarea class="textarea" :placeholder="$t('Customer.DescChiefComplaint')" v-model.trim="getEditedAppointmentData.description" required></textarea>
        </div>
      </div>
      <div v-else>
        <label class="label">{{ $t('Customer.ChiefComplaint') }}</label>
        <div class="control">
          <p>{{ getEditedAppointmentData.description }}</p>
        </div>
      </div>
    </div>
    <div class = "block">
      <!-- Vet Notes -->
      <div class="field" v-if="!addingFile && getEditingAppointment && getAccessType == 'vet'">
        <label class="label">{{ $t('AppointmentData.VetObservations') }}</label>
        <div class="control">
          <textarea class="textarea" :placeholder="$t('AppointmentData.VetObservationsPlaceHolder')" v-model.trim="getEditedAppointmentData.vet_observations" required></textarea>
        </div>
      </div>
      <div v-else-if="getAccessType == 'vet' || getAccessType == 'manager'">
        <label class="label">{{ $t('AppointmentData.VetObservations') }}</label>
        <div class="control">
          <p>{{ getEditedAppointmentData.vet_observations }}</p>
        </div>
      </div>
      <!--<div class="field">
        <label class="label mt-4">Files</label>
        <div class="control">
          <input type="file" @change="handleFileUpload" multiple>
        </div>
      </div>-->
      <div class="content-body" v-if="!addingFile">
        <label class="label mt-4">{{ $t('FilesData.Files') }}</label>
        <table class="table">
          <thead>
            <tr>
              <th class="">{{ $t('FilesData.FileName') }}</th>
              <th class="">{{ $t('FilesData.FileContent') }}</th>
              <th class="">{{ $t('FilesData.FileType') }}</th>
              <th class="">{{ $t('FilesData.FileSize') }}</th>
              <th class="">{{ $t('FilesData.FileUploadTime') }}</th>
              <th v-if="getEditingAppointment">{{ $t('General.Operations') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file of getEditedAppointmentFiles" :key="file.file_id">
              <AppointmentDataFileTableRow :file-data="file"></AppointmentDataFileTableRow>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="content-body">
        <div class="header is-flex is-justify-content-space-between" v-if="!addingFile && getEditingAppointment">
          <div>
            <button class="button is-primary" @click="toggleAddingFile"><strong>{{ $t('FilesData.NewFile') }}</strong></button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="field is-grouped is-grouped-right">
          <p class="control" v-if="!addingFile && getEditingAppointment">
            <button type="submit" class="button is-primary">{{ $t('General.Save') }}</button>
          </p>
          <p class="control" v-if="!addingFile && getEditingAppointment">
            <a @click="cancelEditing" class="button is-light">
              {{ $t('General.Cancel') }}
            </a>
          </p>
          <p class="control" v-if="!addingFile && !getEditingAppointment && (getAccessType == 'customer' || getAccessType == 'vet')">
            <a @click="iniAppointmentEditing" class="button is-primary">
              {{ $t('General.ModifyData') }}
            </a>
          </p>
          <p class="control" v-if="getShowingAppointmentData && !getEditingAppointment">
            <a @click="endAppointmentDetailing" class="button is-primary">
              {{ $t('General.Back') }}
            </a>
          </p>
        </div>
      </div>
      <div class="content-body">
        <form @submit.prevent="addFileMeth" v-if="addingFile">
          <div class="header">
            <h5 class="title is-5 mt-4">
              {{ $t('FilesData.AddNewFile') }}
            </h5>
          </div>
          <AppointmentDataFileDataForm ref="refNewFileDataForm"></AppointmentDataFileDataForm>
          <div class="field is-grouped is-grouped-right">
            <p class="control">
              <button type="submit" class="button is-primary">{{ $t('General.Add') }}</button>
            </p>
            <p class="control">
              <a @click="toggleAddingFile" class="button is-light">
                {{ $t('General.Cancel') }}
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from "vuex";
import { Notyf } from "notyf";
import AppointmentDataFileTableRow from "../components/AppointmentDataFileTableRow.vue";
import AppointmentDataFileDataForm from "../components/AppointmentDataFileDataForm.vue";

const notyf = new Notyf()

export default defineComponent({
  name: 'AppointmentDataForm',
  components: { AppointmentDataFileTableRow, AppointmentDataFileDataForm },

  data() {
    return {
      isCreatingAppointment: false,
      isSchedulingMeeting: false,
      appointment_pet_id: 0,
      appointment_vet_id: 0,
      description: '',
      paymentHash: '',
      selectedFiles: [] as File[],
      addingFile: false,
    };
  },
  computed: {
    ...mapGetters(['getUsers', 'userData', 'getEditedCustomerData', 'getEditedCustomerPets', 'getEditedAppointmentData', 'getEditedAppointmentFiles',
      'getEditingAppointment', 'getShowingAppointmentData', 'getEditedAppointmentNewFileData', 'getAppointmentDetailing','getEditedAppointmentFilesToDelete',
      'getAccessType']),
  },
  methods: {
    ...mapActions(['fetchUsers', 'iniEditedCustomer', 'iniEditedCustomerPets', 'IniAccessTypeCustomer', 'iniAppointmentEditing', 'endAppointmentEditing',
      'toggleAppointmentDetailing', 'toggleShowingAppointmentData', 'addFileEditedAppointment', 'iniEditedAppointment',
      'iniEditedAppointmentFiles', 'editAppointment','createEditedAppointmentFile', 'deleteEditedAppointmentFile']),

    /*oldeditAppointmentMeth() {
      this.isCreatingAppointment = true;
      const appointmentFormData = new FormData();

      appointmentFormData.append('appointment_data',
        JSON.stringify
          (
            {
              customer_user_id: this.getEditedCustomerData.user_id,
              pet_id: this.appointment_pet_id,
              vet_user_id: this.appointment_vet_id,
              description: this.description,
              schedule: '0000-00-00 00:00:00',
              timezone_diff: 0
            }
          )
      );

      // Append selected files
      for (const file of this.selectedFiles) {
        appointmentFormData.append('files[]', file);
      }

      this.$store.dispatch('requestAppointment',
        appointmentFormData
      )
        .then((res: any) => {
          this.paymentHash = res.appointment_hash;
          this.isCreatingAppointment = false;
        });
    },*/

    async editAppointmentFilesMeth() {
      console.log("editAppointmentFilesMeth")

      try {
        
        //Update appointment data
        await this.editAppointment(this.getEditedAppointmentData);
        
        //Delete removed files
        for (var fileId of this.getEditedAppointmentFilesToDelete)
        {
          await this.deleteEditedAppointmentFile(fileId);
        }
        //Create new files
        for (var editedFileData of this.getEditedAppointmentFiles)
        {
          if (editedFileData.file_id == 0) {
            const newFileFormData = new FormData();
            newFileFormData.append
            (
              'file_data',
              JSON.stringify
              (
              {
                appointment_id: this.getEditedAppointmentData.appointment_id,
                file_description: editedFileData.file_description,
              }
              )
            );
            // Append file
            newFileFormData.append('files[]', editedFileData.file);
            //create new added file
            await this.createEditedAppointmentFile(newFileFormData)
          }
        }
        this.endAppointmentEditing()
        notyf.success(this.$t('AppointmentData.NotyfAppointmentEdited'))
        this.initializeEditedAppointment()
     }
      catch (e) {
        notyf.error(this.$t('AppointmentData.NotyfAppointmentUpdateError'))
      }
    },


    handleFileUpload(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      if (inputElement && inputElement.files) {
        const files = Array.from(inputElement.files) as File[];
        // Reset selectedFiles array
        this.selectedFiles = [];
        // Add each selected file to selectedFiles array
        for (const file of files) {
          this.selectedFiles.push(file);
        }
      }
    },
    toggleAddingFile() {
      this.addingFile = !this.addingFile
    },

    cancelEditing() {
      this.endAppointmentEditing()
      this.clearFormAppointment()
      this.initializeEditedAppointment()
    },
    endAppointmentDetailing() {
      this.toggleShowingAppointmentData(true);
      this.toggleAppointmentDetailing(true);
      this.clearFormAppointment();
    },
    async addFileMeth() {
      console.log(this.getEditedAppointmentFiles)

      // Calculate the total size of existing files
      const existingFileSize = this.getEditedAppointmentFiles.reduce((total: number, fileData: any) =>
      {
        return total + (fileData.file_size ? parseInt(fileData.file_size, 10) : 0); // Accumulate size of each existing file
      }, 0);

      // Get the new file from getEditedAppointmentNewFileData
      const newFileData = this.getEditedAppointmentNewFileData; // Access new appointment file data
      const newFile: File | null = newFileData.file; // Get the new file
      const newFileSize: number = newFile ? newFile.size : 0; // Size of the new file, default to 0 if not present

      // Calculate the total size including the new file
      const totalFileSize: number = existingFileSize + newFileSize;

      // Determine the maximum allowed file size based on access type
      const maxSize: number = this.getAccessType === 'customer' ? 20 * 1024 * 1024 : 40 * 1024 * 1024; // 20 MB for customer, 40 MB for vet

      // Check if total size exceeds the limit
      if (totalFileSize > maxSize) {
        notyf.error(this.$t('FilesData.MaxFilesSizeExceededMessage'));
        return; // Exit the method if size exceeds the limit
      }

      // Proceed with adding the file
      this.addFileEditedAppointment(newFileData)
      console.log(this.getEditedAppointmentFiles)
      this.toggleAddingFile();
      
      // Cleans the form with pet data using method in child component
      // this.clearFormPet()
      (this.$refs.refNewFileDataForm as any).clearFormFile();
      notyf.success(this.$t('FilesData.NotyfNewFileAdded'))
    },
    clearFormAppointment() {
      this.iniEditedAppointment()
    },
    async initializeEditedAppointment() {
      console.log("initializeEditedAppointment")
      console.log(this.getEditedAppointmentData.appointment_id);
      if (!this.getAppointmentDetailing) {
        console.log(this.userData.email);
        const resultIniAppointment = await this.iniEditedAppointment(this.getEditedAppointmentData.appointment_id);
        console.log(resultIniAppointment);
      }
      console.log(this.getEditedAppointmentData);
      console.log(this.getEditedAppointmentData.appointment_id);
      const resultIniFiles = await this.iniEditedAppointmentFiles(this.getEditedAppointmentData.appointment_id);
      console.log(resultIniFiles);
      console.log(this.getEditedAppointmentFiles);

    }
  },

  beforeMount() {
    this.initializeEditedAppointment();
  },

});
</script>
<style scoped>
.smaller-font-size {
  font-size: smaller;
  /* You can adjust this value as needed */
}
</style>
