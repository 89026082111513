<template>
    <section class="hero is-fullheight p-6">
      <div class="container has-background-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
        <div>
          <img :src="logoPath" class="image logo-image" alt="logo">
        </div>
        <form @submit.prevent="sendPasswordResetLink" class="p-3">
          <div class="field">
            <p class="control has-icons-left">
              <input class="input" type="email" :placeholder="$t('General.EmailPlaceholder')" v-model="email" required>
            </p>
          </div>
          <div class="field">
            <p class="control has-text-centered">
              <button class="button is-success is-fullwidth" :class="{ 'is-loading': isProcessing }">
                {{ $t('General.SendPasswordResetEmail') }}
              </button>
            </p>
          </div>
        </form>
      </div>
    </section>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import { mapActions, mapGetters } from "vuex";
  import { Notyf } from "notyf";
  
  const notyf = new Notyf();
  
  export default defineComponent({
    name: "ResetPassword",
    data() {
      return {
        email: '',
        isProcessing: false,
        logoPath: process.env.VUE_APP_LOGO_URL // Access the Logo URL from environment variables
      };
    },
    methods: {
      ...mapActions(['sendPasswordResetEmail']),
      async sendPasswordResetLink() {
        this.isProcessing = true;
        try
        {
          const language = this.$i18n.locale; // Obtain the current i18n language
          const result = await this.sendPasswordResetEmail({ email: this.email, language });
          if (result)
          {
            notyf.success(this.$t('General.PasswordResetLinkSent'));
            this.$router.push('/');
          }
          else
          {
            notyf.error(this.$t('General.ErrorSendingLink'));
          }
        }
        catch (error)
        {
          notyf.error(this.$t('General.ErrorSendingLink'));
        }
        finally
        {
          this.isProcessing = false;
        }
      }
    }
  });
  </script>
  
  <style scoped>
  .logo-image {
    height: 8em;
  }
  </style>