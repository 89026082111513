<template>
  <div>
    <Navbar></Navbar>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="content">
            <div class="header is-flex is-justify-content-space-between">
              <div>
                <h4 class="title is-4">
                  {{ $t('Management.Mode') }}
                </h4>
                <h6 class="subtitle is-6 has-text-grey" v-if="getMngMode == 'vets' && !creating">
                  {{ $t('Management.ManageVets') }}
                </h6>
                <h6 class="subtitle is-6 has-text-grey" v-if="getMngMode == 'managers' && !creating">
                  {{ $t('Management.ManageManagers') }}
                </h6>
                <h6 class="subtitle is-6 has-text-grey" v-if="getMngMode == 'customers' && !creating">
                  {{ $t('Management.VisualizeCustomers') }}
                </h6>
                <h6 class="subtitle is-6 has-text-grey" v-if="getMngMode == 'appointments' && !creating">
                  {{ $t('Management.VisualizeAppointments') }}
                </h6>
                <h6 class="subtitle is-6 has-text-grey" v-if="getMngMode == 'products' && !creating">
                  {{ $t('Management.ManageProducts') }}
                </h6>
              </div>
              <div>
                <button class="button is-primary" @click="iniCreating"
                  v-if="getMngMode == 'vets' && !creating"><strong>{{ $t('Management.NewVet') }}</strong></button>
                <button class="button is-primary" @click="iniCreating"
                  v-if="getMngMode == 'managers' && !creating"><strong>{{ $t('Management.NewManager') }}</strong></button>
              </div>
            </div>
            <div class="content-body">
              <!-- Vets, Managers and Customers list  -->
              <table class="table" v-if="getMngMode != 'appointments' && getMngMode != 'products' && !creating">
                <thead>
                  <tr>
                    <th>{{ $t('UserData.Id') }}</th>
                    <th v-if="getMngMode != 'customers'">{{ $t('UserData.Photo') }}</th>
                    <th class="">{{ $t('UserData.Name') }}</th>
                    <th class="">{{ $t('UserData.Email') }}</th>
                    <th class="" v-if="getMngMode != 'managers'">{{ $t('UserData.Phone') }}</th>
                    <th class="" v-if="getMngMode == 'customers'">{{ $t('CustomerData.Address') }}</th>
                    <th class="" v-if="getMngMode == 'vets'">{{ $t('VetData.Specialty') }}</th>
                    <th class="" v-if="getMngMode == 'customers'">{{ $t('CustomerData.VATNumber') }}</th>
                    <th v-if="getMngMode != 'customers'">{{ $t('General.Operations') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user of getUsers" :key="user.user_id">
                    <UserTableRow :user-data="user" :parentShowCustomerData="showCustomerData"></UserTableRow>
                  </tr>
                </tbody>
              </table>

              <!-- Appointments list  -->
              <!-- Vet Filter -->
              <div v-if="getMngMode == 'appointments' && !getShowingAppointmentData" class="field is-grouped mb-4">
                <label class="label mr-2">{{ $t('Management.OfVet') }}</label>
                <div class="control">
                  <select v-model="filterVetUserId" required class="select is-fullwidth px-6 is-size-6">
                    <option value="" disabled selected>{{ $t('Management.SelectVet') }}</option>
                    <option v-for="vet of getUsers" :key="vet.user_id" :value="vet.user_id">{{ vet.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- Date Range Filter -->
              <div v-if="getMngMode == 'appointments' && !getShowingAppointmentData" class="field is-grouped mb-4">
                <label class="label mr-2">{{ $t('General.From') }}</label>
                <div class="control">
                  <VueDatePicker id="filter_start_date" v-model="filterStartDate" :placeholder="$t('General.PleaseSelectDate')"
                  model-type="yyyy-MM-dd" auto-apply :min-date="new Date(1950, 0, 1)" :max-date="maxDate"
                  :enable-time-picker="false" prevent-min-max-navigation format="yyyy-MM-dd"/> 
                </div>
                <label class="label mr-2">{{ $t('General.To') }}</label>
                <div class="control">
                  <VueDatePicker id="filter_end_date" v-model="filterEndDate" :placeholder="$t('General.PleaseSelectDate')"
                  model-type="yyyy-MM-dd" auto-apply :min-date="new Date(1950, 0, 1)" :max-date="maxDate"
                  :enable-time-picker="false" prevent-min-max-navigation format="yyyy-MM-dd"/>
                </div>
                <div class="control">
                  <button @click="resetFilter" class="button is-light">{{ $t('General.ResetFilter') }}</button>
                </div>
              </div>
              
              <div class="table-container mb-2 " v-if="getMngMode == 'appointments' && !getShowingAppointmentData" style="max-height: 420px; overflow-y: auto;">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="">{{ $t('AppointmentData.Appointment') }}</th>
                      <th class="">{{ $t('AppointmentData.Vet') }}</th>
                      <th class="">{{ $t('AppointmentData.Customer') }}</th>
                      <th class="">{{ $t('AppointmentData.Pet') }}</th>
                      <th class="">{{ $t('AppointmentData.Price') }}</th>
                      <th class="">{{ $t('AppointmentData.Status') }}</th>
                      <th class="">{{ $t('AppointmentData.StatusTime') }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="appointment of filteredAppointments" :key="appointment.appointment_id">
                      <MngAppointmentRecordTableRow :appointment-data="appointment"></MngAppointmentRecordTableRow>
                    </tr>
                  </tbody>
                </table>
              </div>
              <AppointmentDataForm v-if="getMngMode == 'appointments' && getShowingAppointmentData"></AppointmentDataForm>
              
              <!-- Export to Excel -->
              <button @click="exportAppointmentsToExcel" v-if="getMngMode == 'appointments' && !getShowingAppointmentData" class="button is-primary">
                {{ $t('Management.ExportToExcel') }}
              </button>

              <!-- Products list  -->
              <table class="table" v-if="getMngMode == 'products'">
                <thead>
                  <tr>
                    <th>{{ $t('ProductData.Id') }}</th>
                    <th class="">{{ $t('ProductData.Designation') }}</th>
                    <th class="">{{ $t('ProductData.Price') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product of getProducts" :key="product.product_id">
                    <ProductTableRow :product-data="product"></ProductTableRow>
                  </tr>
                </tbody>
              </table>

              
              <!-- Form to create a new user -->
              <form @submit.prevent="createUserMeth" v-if="creating">
                <div class="header">
                  <h5 class="title is-5" v-if="getMngMode == 'vets'">
                    {{ $t('Management.NewVet') }}
                  </h5>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <label class="label">{{ $t('UserData.Name') }}</label>
                      <div class="control">
                        <input :minlength='2' v-model="newUser.name" class="input" type="text"
                        :placeholder="$t('UserData.NamePlaceholder')" required>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field">
                      <label class="label">{{ $t('UserData.Email') }}</label>
                      <div class="control">
                        <input v-model="newUser.email" class="input" type="email"
                        :placeholder="replaceSpecialCharacters($t('UserData.EmailPlaceholder'))" required>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <label class="label">{{ $t('UserData.Password') }}</label>
                      <div class="control">
                        <input :minlength='6' v-model="newUser.password" class="input" type="password"
                        :placeholder="$t('UserData.PasswordPlaceholder')" required>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field">
                      <label class="label">{{ $t('CustomerData.PassConfirmation') }}</label>
                      <div class="control">
                        <input :minlength='6' v-model="newUser.confirmation" class="input" type="password"
                        :placeholder="$t('UserData.PassConfirmationPlaceholder')" required>
                      </div>
                    </div>
                  </div>
                  <div class="column" v-if="getMngMode != 'managers'">
                    <div class="field">
                      <label class="label">{{ $t('UserData.PhoneNumber') }}</label>
                      <div class="control">
                        <input :minlength='6' v-model="newUser.phone" class="input" type="phone"
                        :placeholder="$t('UserData.PhoneNumberPlaceholder')" required>
                      </div>
                    </div>
                  </div>
                  <div class="column" v-if="getMngMode == 'vets'">
                    <div class="field">
                      <label class="label">{{ $t('VetData.Specialty') }}</label>
                      <div class="control">
                        <input :minlength='6' v-model="newUser.specialty" class="input" type="test"
                        :placeholder="$t('VetData.SpecialtyPlaceholder')" required>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-grouped is-grouped-right">
                  <p class="control">
                    <button type="submit" class="button is-primary">{{ $t('General.Save') }}</button>
                  </p>
                  <p class="control">
                    <a @click="toggleCreating" class="button is-light">
                      {{ $t('General.Cancel') }}
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Navbar from "../components/Navbar.vue";
import { Notyf } from "notyf";
import UserTableRow from "../components/UserTableRow.vue";
import ProductTableRow from "../components/ProductTableRow.vue";
import AppointmentDataForm from "../components/AppointmentDataForm.vue";
import MngAppointmentRecordTableRow from "../components/MngAppointmentRecordTableRow.vue";
import * as XLSX from 'xlsx';

interface MngAppointmentRecord {
    appointment_id: number,
    schedule: string,
    vet_user_id: number,
    vet_name: string,
    customer_user_id: number,
    customer_name: string,
    pet_id: number,
    pet_name: string,
    description: string,
    price: number,
    appointment_status: string,
    status_date: string
}

const notyf = new Notyf()

export default defineComponent({
  name: "Management",
  components: { UserTableRow, Navbar, MngAppointmentRecordTableRow, AppointmentDataForm, ProductTableRow },

  data() {
    return {
      componentName: "Management",
      creating: false,
      newUser: {
        user_id: 0,
        name: '',
        email: '',
        password: '',
        confirmation: '',
        phone: '',
        specialty: '',
        type: ''
      },
      filterStartDate: '',
      filterEndDate: '',
      filterVetUserId: ''
    }
  },
  computed: {
    ...mapGetters(['getUsers', 'getMngMode', 'getAccessType', 'getShowingAppointmentData', 'getMngAppointmentsRecord', 'getProducts']),
    validPassword(): boolean {
      return (this.newUser.password === this.newUser.confirmation && this.newUser.password.length >= 6)
    },
    // Compute maxDate as today plus one month
    maxDate(): Date
      {
        const today = new Date();
        const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
        return nextMonth;
      },
      
      filteredAppointments(): MngAppointmentRecord[]
      {
        return (this.getMngAppointmentsRecord as MngAppointmentRecord[]).filter(
          (appointment: MngAppointmentRecord) =>
          {
            const appointmentDate = new Date(appointment.schedule);
            const start = this.filterStartDate
              ? new Date(this.filterStartDate)
              : null;
            const end = this.filterEndDate ? new Date(this.filterEndDate) : null;
            const vetFilter = this.filterVetUserId
              ? appointment.vet_user_id === Number(this.filterVetUserId)
              : true;

            return (
              vetFilter &&
              (!start || appointmentDate >= start) &&
              (!end || appointmentDate <= end)
            );
          }
        );
      }
  },
  methods: {
    ...mapActions(['createUser', 'deleteUser', 'iniCustDetailing', 'toggleShowingUserData', 'toggleCustDetailing', 'toggleShowingPetRecord',
                   'iniMngAppointmentsRecord', 'fetchProducts']),

    /*async deleteBtn(id: string) {
      if (confirm('Do you want to remove this user?')) {
        //TODO:Create notification to ask if it is really to delete
        try {
          await this.deleteUser(id);
          notyf.success(`User removed!`)
        } catch (e) {
          notyf.error(`Error while removing user!`)
        }
      }
    },*/

    replaceSpecialCharacters(text: string) {
      console.log('Original text:', text);
      // Regular expression to replace '%at%' with '@'
      const replacedText = text.replace('%at%', '@');
      console.log('Replaced text:', replacedText);
      return replacedText;
    },

    async createUserMeth() {
      if (this.validPassword) {
        try {
          const create = await this.createUser(this.newUser)
          if (create) {
            this.toggleCreating()
            notyf.success(this.$t('UserData.NotyfNewUserAdded'))
          }
        } catch (e) {
          notyf.error(this.$t('UserData.NotyfErrorCreatingUser'))
        }
      } else {
        notyf.error(this.$t('UserData.NotyfPasswordsMismatch'))
      }
    },
    clearForm() {
      this.newUser = {
        user_id: 0,
        name: '',
        email: '',
        password: '',
        confirmation: '',
        phone: '',
        specialty: '',
        type: '',
      }
    },
    toggleCreating() {
      this.creating = !this.creating
    },

    iniCreating() {
      this.clearForm();
      switch (this.getMngMode) {
        case "vets":
          this.newUser.type = 'vet'
          break;
        case "managers":
          this.newUser.type = 'manager'
          break;
        default:
          this.newUser.type = 'customer'
          break;
      }
      this.toggleCreating();
    },

    showCustomerData(userData: any) {
      this.iniCustDetailing(userData);
      this.toggleShowingUserData(false);
      this.toggleCustDetailing(false);
      const language = this.$i18n.locale;
      const accessType = this.getAccessType;
      this.$router.push({ path: "/customerdata", query: { lang: language, access: accessType } });
    },
    resetFilter()
    {
      // Set filter dates to 15 days before and after today
      const today = new Date();
      const fifteenDays = 15;

      // Start date: 15 days before today
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - fifteenDays);
      this.filterStartDate = startDate.toISOString().split('T')[0]; // Format as yyyy-MM-dd

      // End date: 15 days after today
      const endDate = new Date(today);
      endDate.setDate(today.getDate() + fifteenDays);
      this.filterEndDate = endDate.toISOString().split('T')[0]; // Format as yyyy-MM-dd

      // Reset vet filter
      this.filterVetUserId = '';
    },

    exportAppointmentsToExcel() {
      // Collect the appointment data
      const appointments = this.filteredAppointments;

      // Translate field names
      const translatedFields = {
        appointment: this.$t('AppointmentData.Appointment'),
        vet: this.$t('AppointmentData.Vet'),
        customer: this.$t('AppointmentData.Customer'),
        pet: this.$t('AppointmentData.Pet'),
        price: this.$t('AppointmentData.Price'),
        status: this.$t('AppointmentData.Status'),
        statusTime: this.$t('AppointmentData.StatusTime')
      };

      // Select specific fields to export with translated names
      const selectedFields = appointments.map(appointment => ({
        [translatedFields.appointment]: appointment.schedule,
        [translatedFields.vet]: appointment.vet_name,
        [translatedFields.customer]: appointment.customer_name,
        [translatedFields.pet]: appointment.pet_name,
        [translatedFields.price]: {v: appointment.price, t: 'n'},
        [translatedFields.status]: appointment.appointment_status,
        [translatedFields.statusTime]: appointment.status_date,
      }));

      // Convert data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(selectedFields);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Appointments');

      // Generate current date and time string in format yyyyMMddHHmmss
      const now = new Date();
      const dateTimeString = now.getFullYear().toString() +
                             (now.getMonth() + 1).toString().padStart(2, '0') +
                             now.getDate().toString().padStart(2, '0') +
                             now.getHours().toString().padStart(2, '0') +
                             now.getMinutes().toString().padStart(2, '0') +
                             now.getSeconds().toString().padStart(2, '0');

      // Translate "appointments"
      const translatedAppointments = this.$t('Management.Appointments');

      // Generate Excel file and trigger download with prefixed date-time
      XLSX.writeFile(workbook, `${dateTimeString}_${translatedAppointments}.xlsx`);
    }

  },
  watch: {},
  mounted() {
  },


  beforeMount() {
    this.toggleShowingUserData(true);
    this.toggleShowingPetRecord(true);
    this.toggleCustDetailing(true);
    console.log("beforeMount");
  },
  async created() {
    const language = this.$i18n.locale;
    await this.iniMngAppointmentsRecord(language);
    // Set initial filter
    this.resetFilter();
    await this.fetchProducts(language);
    console.log("end of created hook");
  }
})
</script>

<style scoped>
.content-body {
  margin-top: 10px;
}
</style>
