<template>
  <div>
    <Navbar></Navbar>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="content">
            <form @submit.prevent="createCustomerMeth">
              <div class="content-body">
                <form @submit.prevent="createCustomerMeth">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('CustomerData.PleaseEnterYourCustomerData') }}
                    </h5>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Name') }}</label>
                        <div class="control">
                          <input :minlength='2' v-model="getNewCustomerData.name" class="input" type="text"
                          :placeholder="$t('CustomerData.NamePlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Email') }}</label>
                        <div class="control">
                          <input v-model="getNewCustomerData.email" class="input" type="email"
                          :placeholder="replaceSpecialCharacters($t('CustomerData.EmailPlaceholder'))" required>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('UserData.PhoneNumber') }}</label>
                        <div class="control">
                          <input :minlength='6' v-model="getNewCustomerData.phone" class="input" type="tel"
                          :placeholder="$t('UserData.PhoneNumberPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.VATNumber') }}</label>
                        <div class="control">
                          <input v-model="getNewCustomerData.tax_id" class="input" type="text"
                          :placeholder="$t('CustomerData.VATNumberPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Password') }}</label>
                        <div class="control">
                          <input :minlength='6' v-model="getNewCustomerData.password" class="input" type="password"
                          :placeholder="$t('CustomerData.PasswordPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.PassConfirmation') }}</label>
                        <div class="control">
                          <input :minlength='6' v-model="getNewCustomerData.confirmation" class="input" type="password"
                          :placeholder="$t('CustomerData.PassConfirmationPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Address') }}</label>
                        <div class="control">
                          <input v-model="getNewCustomerData.address" class="input" type="text"
                          :placeholder="$t('CustomerData.AddressPlaceholder')" required>
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.VetCenter') }}</label>
                        <div class="control">
                          <input v-model="getNewCustomerData.vet_center" class="input" type="email"
                          :placeholder="$t('CustomerData.VetCenterPlaceholder')" required>
                        </div>
                      </div>
                    </div>                  
                  </div>
                </form>
              </div>
              <!-- Adding pets in this page was deactivated due to security concerns
              
              <div class="header">
                <h5 class="title is-6 mt-4" v-if="!addingPet && !getEditingPet">
                  {{ $t('CustomerData.YourPets') }}
                </h5>
              </div>
              <div class="content-body">
                <table class="table" v-if="!addingPet && !getEditingPet">
                  <thead>
                    <tr>
                      <th class="">{{ $t('PetData.PetName') }}</th>
                      <th class="">{{ $t('PetData.Species') }}</th>
                      <th class="">{{ $t('PetData.Breed') }}</th>
                      <th class="">{{ $t('PetData.Gender') }}</th>
                      <th class="">{{ $t('PetData.Birth') }}</th>
                      <th class="">{{ $t('PetData.Age') }}</th>
                      <th class="">{{ $t('PetData.FertileStatus') }}</th>
                      <th class="">{{ $t('PetData.Weight') }}</th>
                      <th>{{ $t('General.Operations') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="pet of getNewCustomerPets" :key="pet.pet_id">
                      <PetTableRow :pet-data="pet"></PetTableRow>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <div class="content-body">
                <div class="header is-flex is-justify-content-space-between" v-if="!addingPet && !getEditingPet">
                  <div>
                    <button class="button is-primary" @click="toggleAddingPet"><strong>{{ $t('CustomerData.NewPet') }}</strong></button>
                  </div>
                </div>
              </div>
              -->
              <div class="content-body">
                <div class="field is-grouped is-grouped-right">
                  <p class="control" v-if="!addingPet && !getEditingPet">
                    <button type="submit" class="button is-primary">{{ $t('General.Save') }}</button>
                  </p>
                  <p class="control" v-if="!addingPet && !getEditingPet">
                    <a @click="cancelRegistration" class="button is-light">
                      {{ $t('General.Cancel') }}
                    </a>
                  </p>
                </div>
              </div>
              
              <!-- Adding pets in this page was deactivated due to security concerns
              <div class="content-body">
                <form @submit.prevent="addPetMeth" v-if="addingPet && !getEditingPet">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('CustomerData.AddNewPet') }}
                    </h5>
                  </div>
                  <PetDataForm ref="refNewPetDataForm"></PetDataForm>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control">
                      <button type="submit" class="button is-primary">{{ $t('General.Add') }}</button>
                    </p>
                    <p class="control">
                      <a @click="toggleAddingPet" class="button is-light">
                        {{ $t('General.Cancel') }}
                      </a>
                    </p>
                  </div>
                </form>
              </div>
              <div class="content-body">
                <form @submit.prevent="editPetMeth" v-if="getEditingPet">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('CustomerData.EditPet') }}
                    </h5>
                  </div>
                  <PetDataForm ref="refEditedPetDataForm"></PetDataForm>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control">
                      <button type="submit" class="button is-primary">{{ $t('General.Edit') }}</button>
                    </p>
                    <p class="control">
                      <a @click="cancelEditPet" class="button is-light">
                        {{ $t('General.Cancel') }}
                      </a>
                    </p>
                  </div>
                </form>
              </div>
              -->
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Navbar from "../components/Navbar.vue";
import { Notyf } from "notyf";
// Adding pets in this page was deactivated due to security concerns
//import PetTableRow from "../components/PetTableRow.vue";
//import PetDataForm from "../components/PetDataForm.vue";

const notyf = new Notyf()

export default defineComponent({
  name: "CustomerRegistration",
  components: { /*PetTableRow,*/ Navbar, /*PetDataForm*/},

  data() {
    return {
      componentName: "CustomerRegistration",
      addingPet: false,
      otherSpecies: false,
    }
  },
  computed: {
    ...mapGetters(['getNewCustomerData', 'getNewCustomerPets', 'getNewPetData', 'getBreeds', 'getEditingPet', 'getInitialPetData', 'userData']),
    validPassword(): boolean {
      return (this.getNewCustomerData.password === this.getNewCustomerData.confirmation && this.getNewCustomerData.password.length >= 6)
    },
  },
  methods: {
    ...mapActions([/*'addPet', 'iniNewPet',*/ 'checkEmailIsTaken', 'createCustomer', /*'createPet',*/ 'iniNewCustomer'/*,
      'updateBreeds', 'fetchPets', 'deletePet', 'toggleEditingPet', 'editPet'*/]),

    replaceSpecialCharacters(text: string) {
      console.log('Original text:', text);
      // Regular expression to replace '%at%' with '@'
      const replacedText = text.replace('%at%', '@');
      console.log('Replaced text:', replacedText);
      return replacedText;
    },

    async createCustomerMeth() {
      //Check if fields are filled
      if (!this.getNewCustomerData.name || !this.getNewCustomerData.email || !this.getNewCustomerData.phone || !this.getNewCustomerData.tax_id ||
          !this.getNewCustomerData.password || !this.getNewCustomerData.confirmation || !this.getNewCustomerData.address || !this.getNewCustomerData.vet_center) {
        notyf.error(this.$t('CustomerData.NotyfFillAllFields'))
        return
      }
      const checkEmailIsTaken = await this.checkEmailIsTaken(this.getNewCustomerData.email)
      if (!checkEmailIsTaken) {
        if (this.validPassword) {
          try {
            var newCustomerId: number = await this.createCustomer(this.getNewCustomerData)
            console.log(newCustomerId)

            if (newCustomerId) {
              /*Adding pets in this page was deactivated due to security concerns
              //Create Pets in the customer
              for (var newPetData of this.getNewCustomerPets) {
                newPetData.user_id = newCustomerId;
                await this.createPet(newPetData)
              }
              */
              this.clearFormCustomer()
              notyf.success(this.$t('UserData.NotyfNewUserAdded'))

              this.$router.push('/')
            }
          } catch (e) {
            notyf.error(this.$t('UserData.NotyfErrorCreatingUser'))
          }
        } else {
          notyf.error(this.$t('UserData.NotyfErrorPasswordsMismatch'))
        }
      } else {
        notyf.error(this.$t('CustomerData.NotyfEmailInUse'))
      }
    },

    cancelRegistration() {
      this.clearFormCustomer()
      this.$router.push("/")
    },

    /*async deleteBtn(id: string, $t: any) {
      if (confirm($t && $t('PetData.ConfirmDelete'))) {
        //TODO:Create notification to ask if it is really to delete
        try {
          await this.deletePet(id);
          notyf.success($t('PetData.NotyfPetRemoved'))
        } catch (e) {
          notyf.error($t('PetData.NotyfErrorRemovePet'))
        }
      }
    },*/

    /* Adding pets in this page was deactivated due to security concerns
    async addPetMeth() {
      this.toggleAddingPet();
      console.log(this.getNewCustomerPets)
      this.getNewPetData.pet_id = this.getNewCustomerPets.length
      this.addPet(this.getNewPetData)
      console.log(this.getNewCustomerPets);
      // Cleans the form with pet data using method in child component
      // this.clearFormPet()
      (this.$refs.refNewPetDataForm as any).clearFormPet();
      notyf.success(this.$t('CustomerData.NotyfNewPetAdded'))
    },
    */

    /* Adding pets in this page was deactivated due to security concerns
    async editPetMeth() {
      console.log(this.getNewCustomerPets)
      console.log(this.getNewPetData)
      console.log('editPetMeth')
      this.editPet(this.getNewPetData)
      console.log(this.getNewCustomerPets)
      this.toggleEditingPet(true);
      // Cleans the form with pet data using method in child component
      // this.clearFormPet()
      (this.$refs.refEditedPetDataForm as any).clearFormPet();
      notyf.success(this.$t('CustomerData.NotyfPetModified'))
    },

    cancelEditPet() {
      console.log('cancelPetMeth');
      (this.$refs.refEditedPetDataForm as any).clearFormPet();
      this.toggleEditingPet(true)
    },
    */


    clearFormCustomer() {
      this.iniNewCustomer()
    },

    /* Adding pets in this page was deactivated due to security concerns
    toggleAddingPet() {
      this.addingPet = !this.addingPet
    },
    */
  },
  watch: {},
  mounted() { }
})
</script>

<style scoped>
.content-body {
  margin-top: 10px;
}

.content-body {
  margin-top: 10px;
}
</style>