interface Product {
    product_id: number,
    designation: string,
    price: number,
}


interface ProductPriceParams {
    product_id: number;
    price: number;
}

const productsResetState = () => ({
    products: [] as Product[],
    showingProductData: false
});

export const products = {
    state: {
        products: [] as Product[],
        showingProductData: false,
    },
    mutations: {
        setProducts(state: any, products: Product[]) {
            state.products = products
        },
        setShowingProductData(state: any, showingProductData: boolean) {
            state.showingProductData = showingProductData
        }
    },
    getters: {
        getProducts(state: any): Product[] {
            return state.products
        },
        getShowingProductData(state: any): boolean {
            return (state.showingProductData)
        }
    },
    actions: {
        fetchProducts(state: any , language : string) {
            return new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/products/` + language,
                    {
                        method: 'GET',
                        headers:
                        {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then(res => res.json())
                    .then(products => {
                        state.commit('setProducts', products)
                        resolve(products)
                    })
                    .catch(err => reject(err))
            })
        },

        async createProduct(state: any, newProduct: Product) {
            const response = await fetch(process.env.BASE_URL + `api/products`,
                {
                    method: 'POST',
                    headers:
                    {
                        Authorization: `Bearer ${state.getters.token}`,
                    },
                    body: JSON.stringify(newProduct)

            })

            if (response.ok) {
                state.dispatch("fetchProducts")
                return true
            } else {
                throw new Error('error_creating_new_product')
            }
        },

        async deleteProduct(state: any, product: Product) {
            const response = await fetch(process.env.BASE_URL + `api/products/${product.product_id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                state.dispatch("fetchProducts")
                return true;
            } else {
                throw new Error('error_removing_product')
            }
        },

        async changePrice(state: any, params: ProductPriceParams) {
            const response = await fetch(process.env.BASE_URL + `api/products/price/${params.product_id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    price: params.price
                }),
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                return true;
            } else {
                throw new Error('error_changing_product_price')
            }
        },

        toggleShowingProductData(state: any, showingProductData: boolean) {
            console.log("toggleShowingProductData")
            state.commit('setShowingProductData', !showingProductData)
        }
    }
}
