import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Customer from '../views/Customer.vue'
import Appointment from "../views/Appointment.vue"
import RequestSent from "../views/RequestSent.vue"
import RequestConfirmed from "../views/RequestConfirmed.vue"
import ConfirmAppointment from "../views/ConfirmAppointment.vue"
import JitsiIframe from "../components/JitsiIframe.vue"
import Management from "../views/Management.vue"
import Vet from "../views/Vet.vue"
import CustomerRegistration from "../views/CustomerRegistration.vue"
import CustomerData from "../views/CustomerData.vue"
import ManagerData from "../views/ManagerData.vue"
import VetData from "../views/VetData.vue"
import Login from "../views/Login.vue"
import AuthCallback from "../views/AuthCallback.vue"
import ResetPassword from '@/views/ResetPassword.vue'
import NewPassword from '@/views/NewPassword.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/registration',
        name: 'Registration',
        component: CustomerRegistration
    },
    {
        path: '/resetpassword',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/newpassword',
        name: 'NewPassword',
        component: NewPassword
    },
    {
        path: '/customer',
        name: 'Customer',
        meta: { requiresAuth: true },
        component: Customer
    },
    {
        path: '/customerdata',
        name: 'CustomerData',
        component: CustomerData
    },
    {
        path: '/vetdata',
        name: 'VetData',
        component: VetData
    },
    {
        path: '/auth/callback',
        name: 'AuthCallback',
        component: AuthCallback, // Replace with your actual component
        beforeEnter: (to, from, next) => {
          // Process the callback parameters here
          console.log('beforeEnter');
          console.log(to.query);
          const { token, state } = to.query;
          // Handle the token and state as needed
          next();
        }
    },
    {
        path: '/managerdata',
        name: 'ManagerData',
        component: ManagerData
    },
    {
        path: '/appointment/:pin?',
        name: 'Appointment',
        component: Appointment
    },
    {
        path: '/requestsent/:payment_hash',
        name: 'Request Sent',
        component: RequestSent
    },
    {
        path: '/requestconfirmed/',
        name: 'Request Confirmed',
        component: RequestConfirmed
    },
    {
        path: '/confirmappointment/:hash',
        name: 'Appointment Confirmation',
        component: ConfirmAppointment
    }, {
        path: '/manager',
        name: 'Management',
        meta: { requiresAuth: true },
        component: Management,
    },
    {
        path: '/vet',
        name: 'Vet',
        meta: { requiresAuth: true},
        component: Vet,
    },
    /*{
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
    }*/
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from) => {
    // instead of having to check every route record with
    // to.matched.some(record => record.meta.requiresAuth)
    // if (to.meta.requiresAuth && !localStorage.getItem('token')) {
    if (to.meta.requiresAuth && from.name != 'Login' && from.name != 'CustomerData' &&
        from.name != 'Request Confirmed' && from.name != 'VetData' && from.name != 'ManagerData')
    {
        // if this route requires auth
        // clear token and redirect to login page.
        localStorage.clear()
        return {
            path: '/',
            // save the location we were at to come back later
            query: { redirect: to.fullPath },
        }
    }
})

export default router
