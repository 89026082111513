import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3953c502"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero is-fullheight p-6" }
const _hoisted_2 = { class: "container has-background-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "field" }
const _hoisted_5 = { class: "control has-icons-left" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "field" }
const _hoisted_8 = { class: "control has-text-centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: _ctx.logoPath,
          class: "image logo-image",
          alt: "logo"
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendPasswordResetLink && _ctx.sendPasswordResetLink(...args)), ["prevent"])),
        class: "p-3"
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              class: "input",
              type: "email",
              placeholder: _ctx.$t('General.EmailPlaceholder'),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
              required: ""
            }, null, 8, _hoisted_6), [
              [_vModelText, _ctx.email]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, [
            _createElementVNode("button", {
              class: _normalizeClass(["button is-success is-fullwidth", { 'is-loading': _ctx.isProcessing }])
            }, _toDisplayString(_ctx.$t('General.SendPasswordResetEmail')), 3)
          ])
        ])
      ], 32)
    ])
  ]))
}