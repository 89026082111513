<template>
  <div class="main-div">
    <div class="container">
      <div class="columns">
        <div class="column is-one-third-desktop is-offset-one-third-desktop">
          <div class="is-flex is-flex-direction-column is-align-items-center">
            <div>
              <img src="https://i.imgur.com/uA3fRLZ.png" class="image" alt="logo">
            </div>
            <div v-if="validating && !hasError" class="is-flex is-flex-direction-column is-justify-content-center">
              <div>{{ $t('Customer.WaitForConfirmation') }}</div>
              <progress class="progress is-primary"></progress>
            </div>
            <div v-if="hasError" class="is-flex is-flex-direction-column is-align-content-center">
              <div class="mt-5 has-text-centered">
                <span class="fa fa-times has-text-danger fa-6x"></span>
              </div>
              <p class="is-size-5 has-text-centered">
                {{ $t('Customer.PaymentProblem') }}
              </p>
              <p class="has-text-grey has-text-centered" v-html="$t('Customer.ContactSupport')">
              </p>
              <p class="control">
                <a @click="goToCustomerDataMeth($t)" class="button is-light">
                  {{ $t('General.OK') }}
                </a>
              </p>
            </div>
            <div class="mt-5" v-if="!validating">
              <span class="fa fa-check has-text-success fa-6x"></span>
            </div>
            <div v-if="!validating" class="mt-5 has-text-centered">
              <p class="is-size-3">
                {{ $t('General.ThankYou') }}
              </p>
              <p class="is-size-5">
                {{ $t('Customer.ScheduleConfirmed') }}
              </p>
              <p class="has-text-grey">
                {{ $t('Customer.ScheduleConfirmedNote') }}
              </p>
              <router-link to="/meeting"></router-link>
              <p class="control">
                <a @click="goToCustomerDataMeth($t)" class="button is-light">
                  {{ $t('General.OK') }}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

export default {
  name: "RequestConfirmed",
}
</script>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router';
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Notyf } from "notyf";
import { useI18n } from 'vue-i18n';
import { onBeforeMount } from 'vue';
import { changeLocale } from "@/main"; // Import the changeLocale function

interface ConfirmPaymentParams {
    payment_hash: string,
    language: string
}

const router = useRouter();
const route = useRoute()
const store = useStore()
const { locale } = useI18n();
const paymentHash = ref()
const validating = ref(true)
const hasError = ref(false)
const notyf = new Notyf()
const lang = route.query.lang as string;

const goToCustomerDataMeth = async ($t : any) => {
  const token = localStorage.getItem('token') || ''
  const userData = JSON.parse(localStorage.getItem('userData') || '{}')
  
  if((token == '') || (userData == '{}'))
  {
    notyf.error($t('General.NotyfInvalidAccess'))
    //router.push({ path: "/", query: { lang: locale.value } });
    router.push("/");
  }
  else
  {
    if(userData.type == "customer")
    {
      const loginData = await store.dispatch('IniAccessType', userData.type)
      //router.push({ path: "/" + store.getters['getAccessType'], query: { lang: locale.value } });
      router.push("/" + store.getters['getAccessType']);
    }
    else
    {
      notyf.error($t('General.NotyfInvalidAccess'))
      //router.push({ path: "/", query: { lang: locale.value } });
      router.push("/");
    }
  }
};

onBeforeMount(async() => {

  if (lang)
  {
    try
    {
      await changeLocale(lang);
    }
    catch (error)
    {
      console.error('Failed to change language:', error);
    }
  }

})

onMounted(async () => {

  //paymentHash.value = route.params.payment_hash
  const confirmPaymentParams: ConfirmPaymentParams = {
    payment_hash: route.query.paymentHash as string,
    language: lang
  }

  /* validate payment */
  try {
    const validated = await store.dispatch('confirmPayment', confirmPaymentParams)

    /* turn off validation state */
    if (validated) {
      validating.value = false
    }/* turn off validation state */
    if (validated) {
      validating.value = false
    }
  } catch (e) {
    hasError.value = true
  }


})
</script>

<style scoped>
.main-div {
  height: 100vh;
  display: flex;
  align-items: center;
}
</style>
