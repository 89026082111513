interface User {
    user_id: number,
    name: string,
    type: string,
}

interface Customer {
    user_id: number,
    name: string
}

interface UserNameParams {
    user_id: number;
    name: string;
}

interface UserEmailParams {
    user_id: number;
    email: string;
}


interface UserPhoneParams {
    user_id: number;
    type: string;
    phone: string;
}

interface UserSpecialtyParams {
    user_id: number;
    specialty: string;
}

interface LoginData {
    email: string;
    password: string;
    type: string;
}

interface UserImageParams {
    user_id: number,
    file: string,
}

interface PasswordResetEmailParams {
    email: string,
    language: string,
}

interface UpdatePasswordParams {
    password: string,
    token: string,
}

const usersResetState = () => ({
    users: [] as User[],
    token: '',
    userData: '{}',
    accessType: 'newCustomer',
    showingUserdata: false,
    initialEmailValue: "",
});

export const users = {
    state: {
        users: [] as User[],
        token: localStorage.getItem('token') || '',
        userData: localStorage.getItem('userData') || '{}',
        accessType: 'newCustomer',
        showingUserdata: false,
        initialEmailValue: "",
    },
    mutations: {
        setUsers(state: any, users: User[]) {
            state.users = users
        },

        token(state: any, token: string) {
            state.token = token
            localStorage.setItem('token', token)
        },

        userData(state: any, userData: any) {
            state.userData = JSON.stringify(userData)
            localStorage.setItem('userData', JSON.stringify(userData))
        },

        setAccessType(state: any, accessType: string) {
            state.accessType = accessType
        },
        setShowingUserData(state: any, showingUserdata: boolean) {
            state.showingUserdata = showingUserdata
        },
        setEditedInitialEmail(state: any, initialEmailValue: string) {
            state.initialEmailValue = initialEmailValue;
        },
        usersResetStateMut(state: any) {
            Object.assign(state, usersResetState())
        }
    },
    getters: {
        getUsers(state: any): User[] {
            return state.users
        },

        token(state: any): string {
            return state.token
        },

        userData(state: any): any {
            return JSON.parse(state.userData)
        },

        getAccessType(state: any): string {
            return state.accessType
        },
        getShowingUserData(state: any): boolean {
            return (state.showingUserdata)
        },
        getEditedInitialEmail(state: any): string {
            return (state.initialEmailValue)
        },
    },
    actions: {
        fetchUsers(state: any, userType: string) {
            return new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/users/` + userType, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    }
                })
                    .then(res => res.json())
                    .then(users => {
                        state.commit('setUsers', users)
                        resolve(users)
                    })
                    .catch(err => reject(err))
            })
        },

        async createUser(state: any, newUser: User) {
            const response = await fetch(process.env.BASE_URL + `api/users/${newUser.type}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
                body: JSON.stringify(newUser)

            })

            if (response.ok) {
                state.dispatch("fetchUsers", newUser.type)
                return true
            } else {
                throw new Error('error_creating_new_user')
            }
        },

        async deleteUser(state: any, user: User) {
            const response = await fetch(process.env.BASE_URL + `api/users/${user.user_id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                state.dispatch("fetchUsers", user.type)
                return true;
            } else {
                throw new Error('error_removing_user')
            }
        },

        async changeName(state: any, params: UserNameParams) {
            const response = await fetch(process.env.BASE_URL + `api/users/name/${params.user_id}`, {
                method: 'POST',
                body: JSON.stringify({
                    name: params.name
                }),
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                return true;
            } else {
                throw new Error('error_changing_user_name')
            }
        },
        async changeEmail(state: any, params: UserEmailParams) {
            const response = await fetch(process.env.BASE_URL + `api/users/email/${params.user_id}`, {
                method: 'POST',
                body: JSON.stringify({
                    email: params.email
                }),
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                return true;
            } else {
                throw new Error('error_changing_user_email')
            }
        },
        async changeSpecialty(state: any, params: UserSpecialtyParams) {
            const response = await fetch(process.env.BASE_URL + `api/users/vetspecialty/${params.user_id}`, {
                method: 'POST',
                body: JSON.stringify({
                    specialty: params.specialty
                }),
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                return true;
            } else {
                throw new Error('error_changing_vet_specialty')
            }
        },

        async changePhone(state: any, params: UserPhoneParams) {
            switch (params.type) {
                case "vet":
                    const response_vet = await fetch(process.env.BASE_URL + `api/users/vetphone/${params.user_id}`, {
                        method: 'POST',
                        body: JSON.stringify({
                            phone: params.phone
                        }),
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    });
                    if (response_vet.ok) {
                        return true;
                    } else {
                        throw new Error('error_changing_vet_phone')
                    }
                    break;
                default:
                    const response = await fetch(process.env.BASE_URL + `api/users/custphone/${params.user_id}`, {
                        method: 'POST',
                        body: JSON.stringify({
                            phone: params.phone
                        }),
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    });
                    if (response.ok) {
                        return true;
                    } else {
                        throw new Error('error_changing_customer_phone')
                    }
            }
        },

        async login(state: any, params: LoginData) {

            const response = await fetch(process.env.BASE_URL + `api/login/`, {
                method: 'POST',
                body: JSON.stringify(params)
            })

            if (response.ok) {
                const loginData = await response.json()

                /* store data */
                state.commit('token', loginData.token)
                state.commit('userData', loginData)
                state.commit('setAccessType', loginData.type)
            } else {
                throw new Error('invalid_login_attempt')
            }
        },

        // This function is used for example when the action returns after a payment using stripe.
        IniAccessType(state: any, accessType: string) {

            state.commit('setAccessType', accessType)
        },

        // This function is used when editing data from users of different types. It is used to verify if the user is changing his email
        IniInitialEmail(state: any, userEmail: string) {

            state.commit('setEditedInitialEmail', userEmail)
        },


        logout(state: any)
        {
             // Clear localStorage
            localStorage.removeItem('token');
            localStorage.removeItem('userData');
            
            // Reset state to initial values
            state.commit('usersResetStateMut');
            state.dispatch('appointmentResetStateMut');
            state.dispatch('customerRegResetStateMut');
            state.dispatch('customerResetStateMut');
            state.dispatch('managerResetStateMut');
            state.dispatch('editManagerResetStateMut');
            state.dispatch('vetResetStateMut');
            state.dispatch('editedAvailabilityConfigResetStateMut');
        },
        
        async uploadUserImage(state: any, params: UserImageParams) {

            const formData = new FormData()
            formData.append('image', params.file)

            const response = await fetch(process.env.BASE_URL + `api/users/image/${params.user_id}`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            if (response.ok) {
                return true;
            } else {
                throw new Error('error_changing_user_image')
            }
        },

        toggleShowingUserData(state: any, showingUserData: boolean) {
            console.log("toggleShowingUserData")
            state.commit('setShowingUserData', !showingUserData)
        },
        
        async checkEmailIsTaken(state: any, newEmail: string) {
            const response = await fetch(process.env.BASE_URL + `api/users/checkemail/` + newEmail, {
                method: 'GET',
            })
            var result: boolean = true

            if (response.ok) {
                const CustomerData = await response.json()
                if (CustomerData.length != 0) {
                    console.log(CustomerData.length)
                }
                else {
                    result = false
                }
            } else {
                throw new Error('error_checking_email')
            }
            return (result)
        },

        async sendPasswordResetEmail(state: any, params: PasswordResetEmailParams )
        {
            const email = params.email;
            try {
                const response = await fetch(process.env.BASE_URL + `api/login/sendpasswordresetemail/${params.language}`, {
                    method: 'POST',
                    body: JSON.stringify({ email }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok)
                {
                    return true;
                }
                else
                {
                    // Handle specific error based on status code or response content
                    console.log('Error sending password reset email:');
                    return false;
                }

            } catch (error) {
                console.log('Error sending password reset email:');
                return false;
            }
        },

        async updatePassword(state: any, params: UpdatePasswordParams)
        {
            const password = params.password;
            const token = params.token;
            try {
              const response = await fetch(process.env.BASE_URL + 'api/login/updatepassword', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ password, token })
              });
        
              if (response.ok) {
                return true;
              } else {
                const errorData = await response.json();
                console.error('Error resetting password:', errorData);
                return false;
              }
            } catch (error) {
              console.error('Error resetting password:', error);
              return false;
            }
        }
    }
}
